import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getList = async (query)=> {
    try {
        const response = await instance.get(`/question`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`/question/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (formData, newImages, removedImages) => {
    const data = new FormData();
  
    // Append form fields
    for (const [key, value] of Object.entries(formData)) {
        data.append(key, value);
    }

    // Append new images
    if (newImages.length > 0) {
        newImages.forEach((file) => {
        data.append('files', file); // Append each file with the key 'files'
        });
    }

    // Add removed image IDs to the form data if necessary
    if (removedImages.length > 0) {
        const removedIds = removedImages.map(img => img.id);
        data.append('removedImages', JSON.stringify(removedIds));
    }

  
    try {
      const response = await instance.post(`/question`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error:', error); // Log the error for debugging
      return null;
    }
  };

export const edit = async (id, formData, newImages, removedImages) => {
    try {
    const data = new FormData();
        console.log(formData);
    // Append form fields
    for (const [key, value] of Object.entries(formData)) {
        data.append(key, value);
    }

    // Append new images
    if (newImages.length > 0) {
        newImages.forEach((file) => {
        data.append('files', file); // Append each file with the key 'files'
        });
    }

    // Add removed image IDs to the form data if necessary
    if (removedImages.length > 0) {
        const removedIds = removedImages.map(img => img.id);
        data.append('removedImages', JSON.stringify(removedIds));
    }

        const response = await instance.put(`/question/${id}`,data,{
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const importQuestionsData = async (body) => {
  try {
    const response = await instance.post("/users/import-questions", body);
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {}
};
  
