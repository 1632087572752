import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { checkToken, refreshAccessToken } from '../api/auth.api';

const UserContext = createContext({
  user: null,
  setUser: () => {},
});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const verifyUser = useCallback(async (token) => {
    try {
      const response = await checkToken(token);
      if (response.isValid && response.user) {
        setUser(response.user);
      } else {
        await refreshToken();
      }
    } catch (error) {
      console.error('Error verifying token:', error);
      logout();
    }
  }, []);

  const refreshToken = useCallback(async () => {
    try {
      const newToken = await refreshAccessToken();
      const refreshedResponse = await checkToken(newToken);
      if (refreshedResponse.isValid && refreshedResponse.user) {
        localStorage.setItem('token', newToken);
        setUser(refreshedResponse.user);
      } else {
        navigate('/login');
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  }, [navigate]);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    navigate('/login');
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      verifyUser(token);
    } else {
      navigate('/login');
    }
  }, [verifyUser, navigate]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
