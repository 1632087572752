import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import headerDefinitions from '../tableheaders/learnerclass.tableheader.json'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import helpers from '../helpers/function.helper';
import LoadingComponent from '../components/Loading';
import { getUsersList } from '../api/users.api';
import Swal from 'sweetalert2';
import viewIcon from '../assets/icons/view.png';
import editIcon from '../assets/icons/edit.png';
import formIcon from '../assets/icons/form.png';
import downloadIcon from '../assets/icons/download.png'; 
import certIcon from '../assets/icons/cert.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import TableHeaderButton from '../components/TableHeaderButton';

const LearnerClass = () => {

  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [isLoading, setLoading] = useState(true);

// -------------------- Data Table ----------------------------------------
  const headersData = headerDefinitions.columns;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataTable, setDatatable] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const tableData = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => headersData, [headersData]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
  useTable(
    {
      columns,
      data: tableData,
    },
    useSortBy,
    usePagination
  );

  const toggleRowSelection = (Id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(Id)) {
        // If already selected, remove it
        return prev.filter((id) => id !== Id);
      } else {
        // Otherwise, add it
        return [...prev, Id];
      }
    });
  };

  const handleSort = async (columnId) => {
    // Will Sort By *accessor* in HeaderJSON File
    setSortConfig((prevSortConfig) => {
      let direction = "ASC";
      if (
        prevSortConfig.key === columnId &&
        prevSortConfig.direction === "ASC"
      ) {
        direction = "DESC";
      }
      return { key: columnId, direction };
    });
  };

  const handleNextPage = () => {
    setPageIndex((current) => {
      const nextPageIndex = current + 1;
      setCanNextPage(nextPageIndex < pageCount - 1);
      setCanPreviousPage(true);
      return nextPageIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((current) => {
      const previousPageIndex = Math.max(0, current - 1);
      setCanPreviousPage(previousPageIndex > 0);
      setCanNextPage(true);
      return previousPageIndex;
    });
  };

// -------------------- Data Table ----------------------------------------

const clearSearch = () => {
  setKeyword("");
  setSortConfig({ key: "", direction: "" });
  setRowsPerPage(10);
  setPageIndex(0);
};

const fetchData = async () => {
  console.log('Fetching data...');
  try {
    const { data, totalPages } = await getUsersList({
      page_number: pageIndex,
      data_per_page: rowsPerPage,
      keyword: keyword,
      sort_key: sortConfig.key,
      sort_order: sortConfig.direction,
    });
    if (data) {
      console.log('Data received:', data);
      setDatatable(data);
      setPageCount(totalPages);
      setCanNextPage(pageIndex < Math.ceil(totalPages / rowsPerPage) - 1);
      setCanPreviousPage(pageIndex > 0);
    }
  } catch (error) {
    console.error("Fetch Data Error:", error);
  } finally {
    setLoading(false);
  }
};

const handleExportData = async () => {
  const swalWithBootstrapButtons = Swal.mixin({});  // Providing an empty object as argument

  let res = await swalWithBootstrapButtons.fire({
    title: "คุณต้องการที่ดาวน์โหลด ใช่หรือไม่",
    icon: "warning",
    confirmButtonText: "ตกลง",
    cancelButtonText: "ยกเลิก",
    reverseButtons: true,
    showCancelButton: true,
  });
  if (res.isConfirmed) {
    try {
      setLoading(true);
      window.open(`${process.env.REACT_APP_BASE_URL}/institution/export`,"_blank");
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }
};


useEffect(() => {
  // fetchData();
}, []);


  return (
      <div className="container-fluid">
        <div className='box-white'>
          {/* Subheader */}
          <div className="subheader mb-4">
            <h4>ห้องเรียนของฉัน</h4>
            <h5>ST001 <span className='text-header'>นาย ธนาชัย สอนมาตย์</span></h5>
          </div>

          {/* Card Section */}
          <div className="row mb-4">
            {/* Card 1 */}
            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="card"> 
                <div className="card-body ">
                  <h6>
                    <span>รหัสห้องเรียน C001</span>
                    <span style={{float:'right', color:'#28A745'}}>กำลังเรียน •</span>
                  </h6>
                  <div className='mb-2 font-weight-bold'
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#DAE6FE",
                      borderRadius: "10px",
                      padding:"10px"
                    }}
                  >
                    <p className='m-0'>ชื่อห้องเรียน: </p>
                    <span className='text-header m-1'>รถไฟฟ้า EV101</span>
                    
                  </div>
                  <p>ระยะเวลา: 1 ส.ค. 2567 ถึง 31 ส.ค. 2567</p>
                  <p className='mb-0'>ผู้สอน: TEA-001 สิริชัย อัครวัฒน์</p>
                  <p>
                    <span className='mx-1'>
                      จำนวนบทเรียน: <span className='font-weight-bold ml-2' style={{color:"#1254E0",fontSize:'30px'}}>10</span>
                    </span> 
                    <span className='mx-1'>
                      คะแนนเก็บ:  <span className='font-weight-bold ml-2' style={{color:"#28A745",fontSize:'30px'}}>50</span>
                    </span>
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <button className="btn btn-outline-primary">ดูข้อมูล</button>
                    <button className="btn btn-outline-secondary">แลกของรางวัล</button>
                    <button className="btn btn-outline-success">ใบรับรอง</button>
                  </div>
                </div>
              </div>
            </div>
            {/* Repeat similar structure for other cards */}

            <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h6>
                    <span>รหัสห้องเรียน C001</span>
                    <span style={{float:'right', color:'#F15A29'}}>เรียนเสร็จสิ้น •</span>
                  </h6>
                  <div className='mb-2 font-weight-bold'
                    style={{
                      width: "100%",
                      height: "100%",
                      backgroundColor: "#DAE6FE",
                      borderRadius: "10px",
                      padding:"10px"
                    }}
                  >
                    <p className='m-0'>ชื่อห้องเรียน: </p>
                    <span className='text-header m-1'>รถไฟฟ้า EV101</span>
                    
                  </div>
                  <p>ระยะเวลา: 1 ส.ค. 2567 ถึง 31 ส.ค. 2567</p>
                  <p className='mb-0'>ผู้สอน: TEA-001 สิริชัย อัครวัฒน์</p>
                  <p>
                    <span className='mx-1'>
                      จำนวนบทเรียน: <span className='font-weight-bold ml-2' style={{color:"#1254E0",fontSize:'30px'}}>10</span>
                    </span> 
                    <span className='mx-1'>
                      คะแนนเก็บ:  <span className='font-weight-bold ml-2' style={{color:"#28A745",fontSize:'30px'}}>200</span>
                    </span>
                  </p>
                  <div className="d-flex justify-content-between mt-3">
                    <button className="btn btn-outline-primary">ดูข้อมูล</button>
                    <button className="btn btn-outline-secondary">แลกของรางวัล</button>
                    <button className="btn btn-outline-success">ใบรับรอง</button>
                  </div>
                </div>
              </div>
            </div>

          </div>



        <div style={{overflow: 'auto' }}>
        <div className="d-flex justify-content-between align-items-center mx-2 py-3">
            <div className='d-none d-md-block'>
              <span className="pr-2 align-self-center text-sm fw-bold">
                Show :{" "}
              </span>
              <label>
                <select
                  style={{ border: "none", cursor: "pointer" }}
                  className="select-page mr-2"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </label>
              <label>Entries</label>
            </div>
            <div className="d-flex search-container">
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="ค้นหา"
                className="search-input"
              />
              <button className="search-button" onClick={fetchData}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
        </div>
          <Table
            striped
            bordered
            {...getTableProps()}
            className="table text-center"
            style={{ borderCollapse: "collapse"}}
          >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={'head'} {...headerGroup.getHeaderGroupProps()}>
                {/* <th>ลำดับ</th> */}
                {headerGroup.headers.map((column) => {
                  const { key, ...rest } = column.getHeaderProps(); // Separate key from other props
                  return (
                    <th key={key} {...rest}>
                      {column.render("Header")}
                      {/* Add a clickable sort icon */}
                      <TableHeaderButton
                        column={column}
                        sortConfig={sortConfig}
                        handleSort={handleSort}
                      />
                    </th>
                  );
                })}
                <th>เครื่องมือ</th>
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            <tr className="space-table"></tr>
            {/* FOR MOCK */}
              <tr>
                <td>1</td>
                <td>รถไฟฟ้า EV101</td>
                <td>1 ส.ค. 2567 ถึง 31 ส.ค. 2567</td>
                <td>10</td>
                <td>TEA-001 สิริชัย อัครวัฒน์</td>
                <td>10</td>
                <td>กำลังเรียน</td>
                <td>
                <button
                  className="icon-btn"
                  style={{ background: "transparent" }}
                  onClick={() => navigate('/myclasssection')}
                >
                  <img
                    src={viewIcon}
                    alt="View"
                    className="ic-actions"
                  />
                </button>

                <button
                  className="mx-1 icon-btn"
                  style={{ background: "transparent" }}
                >
                  <img
                    src={certIcon}
                    alt="Cert"
                    className="ic-actions"
                  />
                </button>

                <button
                  className="btn-chp mx-1 flex-1"
                  onClick={() => navigate('/redeem-reward')}
                >
                  แลกของรางวัล
                </button>

                </td>
              </tr>
            {/* FOR MOCK */}
            {rows.length > 0 ? (
              rows.map((row, rowIndex) => {
                prepareRow(row);
                const actualIndex = pageIndex * rowsPerPage + rowIndex + 1;
                const isSelected = selectedRowIds.includes(row.original.id);

                // Extract key from row.getRowProps()
                const { key, ...rest } = row.getRowProps();
                
                return (
                  <tr
                    key={key} // Pass key directly here
                    {...rest}
                    onClick={() => toggleRowSelection(row.original.id)}
                    className={isSelected ? 'selected-row' : ''}
                    style={isSelected ? { backgroundColor: '#f0f0f0' } : {}}
                  >
                    <td>{actualIndex}</td>
                    <td>{row.original?.ins_code ?? ''}</td>
                    <td>{row.original?.ins_name ?? ''}</td>
                    <td>
                      <span
                        style={{
                          color: row.original.is_active ? '#87BD66' : '#D95350',
                        }}
                      >
                        {helpers.getStatus(row.original.is_active)}
                      </span>
                    </td>
                    <td>
                      <button
                        className="mx-1 icon-btn"
                        style={{ background: 'transparent' }}

                      >
                        <img src={viewIcon} alt="View" className="ic-actions" />
                      </button>

                      <button
                        className="mx-1 icon-btn"
                        style={{ background: 'transparent' }}
                      >
                        <img src={certIcon} alt="Edit" className="ic-actions" />
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr className="text-center">
                <td colSpan={100} style={{ textAlignLast: 'center' }}>
                  ไม่พบข้อมูล
                </td>
              </tr>
            )}
          </tbody>
          </Table>
        </div>

        <div className="py-3 mx-2" style={{ display: "flex", justifyContent: "right" }}>
          {/* Pagination */}
          <div className="mt-2">
            <span>
              {`${pageIndex * rowsPerPage + 1} - ${(pageIndex + 1) * rowsPerPage} จาก ${pageCount} `}
            </span>
            <button
              className="mx-1 px-3 icon-btn"
              onClick={handlePreviousPage}
              disabled={!canPreviousPage}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <span> {pageIndex + 1}</span>
            <button
              className="mx-1 px-3 icon-btn"
              onClick={handleNextPage}
              disabled={!canNextPage}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
            <span>{Math.ceil(pageCount / rowsPerPage)}</span>
          </div>
        </div>

        </div>
      </div>
  );
};

export default LearnerClass;
