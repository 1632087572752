import spinner from '../assets/spinner.gif';
import './Loading.css';

const LoadingComponent = () => (
  <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    <img src={spinner} alt="Loading..." />
  </div>
);

export default LoadingComponent;
