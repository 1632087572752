import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getList = async (query)=> {
    try {
        const response = await instance.get(`${API_BASE_URL}/themecertificate`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`${API_BASE_URL}/themecertificate/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (formData, newImages, removedImages) => {
    try {
      const data = new FormData();
  
      // Append form fields
      for (const [key, value] of Object.entries(formData)) {
          data.append(key, value);
      }
  
      // Append new images
      if (newImages.length > 0) {
          newImages.forEach((file) => {
          data.append('file', file); // Append each file with the key 'files'
          });
      }
  
      // Add removed image IDs to the form data if necessary
      if (removedImages.length > 0) {
          const removedIds = removedImages.map(img => img.id);
          data.append('removedImages', JSON.stringify(removedIds));
      }
        const response = await instance.post(`${API_BASE_URL}/themecertificate`,data);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const edit = async (id, formData, newImages, removedImages) => {
    try {

      const data = new FormData();
      // Append form fields
      for (const [key, value] of Object.entries(formData)) {
          data.append(key, value);
      }

      // Append new images
      if (newImages.length > 0) {
          newImages.forEach((file) => {
          data.append('file', file); // Append each file with the key 'files'
          });
      }

      // Add removed image IDs to the form data if necessary
      if (removedImages.length > 0) {
          const removedIds = removedImages.map(img => img.id);
          data.append('removedImages', JSON.stringify(removedIds));
      }
        const response = await instance.put(`${API_BASE_URL}/themecertificate/${id}`,data);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}
