import React from 'react';
import LoadingComponent from '../components/Loading';

const withLoading = (WrappedComponent) => {
  return function WithLoadingComponent({ isLoading, ...props }) {
    if (isLoading) {
      return <LoadingComponent />;
    }

    return <WrappedComponent {...props} />;
  };
};

export default withLoading;
