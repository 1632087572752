import axios from "axios";

export const getInstance = () => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_ENDPOINT,
  });

  instance.interceptors.response.use(
    (response) => response,
    (error) => {

      return Promise.reject(error);
    }
  );

  instance.interceptors.request.use(
    config => {
      const token = localStorage.getItem('token');
      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
        config.headers['device_type'] = `web`;
      }
      return config;
    },
    error => {
      return Promise.reject(error);
    }
  );

  return instance;
};