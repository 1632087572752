import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="main-footer d-none d-md-block">
      <div className="footer-content">
        <div>
          Copyright © 2024 <a href="https://www.example.com">LMS</a>. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default Footer;
