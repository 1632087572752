import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { showErrorDialog, showEditDialog, showSuccessDialog, showCreateDialog } from "../components/Dialog";
import { getByID, create, edit } from "../api/rewardcate.api";

const RewardCategoryAction = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const method = urlParams.get("method");
  const shouldNavigate = "/reward-category";

  const [isView, setIsView] = useState(false);
  const [formData, setFormData] = useState({
    rec_name: "",
  });

  const isFormValid = () => {
    const { rec_name } = formData;
    // Check if required fields are filled
    if (!rec_name) {
      showErrorDialog("กรุณากรอกข้อมูลให้ครบถ้วน");
      return false;
    }

    // If all fields are valid
    return true;
  };

  const createRewardCateData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await create(formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(shouldNavigate);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(shouldNavigate);
        }
      });
    }
  };

  const updateRewardCateData = async (id) => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await edit(id, formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(shouldNavigate);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(shouldNavigate);
        }
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getByID(id);
        if (response) {
          setFormData({
            rec_name: response?.rec_name,
          });
        } else {
          showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward Category").then((result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(shouldNavigate);
            }
          });
        }
      } catch (error) {
        showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward Category").then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    };

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate(shouldNavigate);
    }

    if (method === "view" || method === "edit") {
      fetchData();
    }

    if (method === "view") {
      setIsView(true);
    }

  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h4 className="px-3 mb-2">หมวดหมู่ ของรางวัล</h4>
          <h5 className="px-3 mb-2 text-header">
            จัดการข้อมูล หมวดหมู่ ของรางวัล
          </h5>

          <Row className="mt-3 px-5">
            <Col xs={12} md={4}>
              <span className="fw-bold font-size03 colorGray01 wh-sp">
                ชื่อประเภท
              </span>
              <input
                disabled={isView}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.rec_name}
                name="rec_name"
                id="rec_name"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate(shouldNavigate);
                    
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createRewardCateData()
                        : updateRewardCateData(id);
                    }}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
        </div>
      </div>
    </div>
  );
};

export default RewardCategoryAction;
