import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import { changePassword, getUsersID } from "../api/users.api";
import withLoading from "../hoc/withLoading";
import EnumRoles from "../constants/EnumRoles";
import { showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";

const UserChangePassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get("user_id");
  const user_type = urlParams.get("user_type");
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [userData, setUserData] = useState(null)

  const [formData, setFormData] = useState({
    new_password: "",
    confirm_pass: "",
    user_type: user_type,
    id: user_id,
  });

  let headerText = "เปลี่ยนรหัสผ่านใหม่";
  let navigate_url = ''
  if (user_type === EnumRoles.TEACHER) {
    headerText = "ผู้สอน | เปลี่ยนรหัสผ่านใหม่";
    navigate_url = "/teacher-user"
  } else if (user_type === EnumRoles.LEARNER) {
    headerText = "ผู้เรียน | เปลี่ยนรหัสผ่านใหม่";
    navigate_url = "/learner-user"
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isFormValid = () => {
    // Check if all fields are filled
    const isEmptyField = Object.entries(formData).some(([key, value]) => {
      return value.trim() === "";
    });

    // Check if the new password matches confirm password
    const isPasswordMatch = formData.new_password === formData.confirm_pass;

    if (isEmptyField) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false;
    }

    if (!isPasswordMatch) {
      showErrorDialog('รหัสผ่าน ไม่ตรงกัน')
      return false;
    }

    return true;
  };

  const fetchData = async () => {
    try {
      const response = await getUsersID(user_id)
      if(response){
        setUserData(response)
      }else{
        showErrorDialog('เกิดข้อผิดพลาด ไม่พบข้อมูล User').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(navigate_url);
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(navigate_url);
        }
      });
    }
  }

  const submitChangePassword = async () => {
    if (!isFormValid()) {
      return;
    }

    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      const response = await changePassword(formData);

      if (response) {
        showSuccessDialog('แก้ไขข้อมูลสำเร็จ')
        navigate(navigate_url);
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(navigate_url);
        }
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData()
  },[])

  return (
    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h4 className="px-3 mb-2">{headerText}</h4>
          <h4 className="px-3 mb-2 text-header">เปลี่ยนรหัสผ่านใหม่</h4>
          <h5 className="px-3 mb-2">{userData?.user_code} {userData?.fullname}</h5>
          <Row className="mt-3 px-5">
            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                รหัสผ่านใหม่
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="password"
                className="inp-login fontLight font-size03"
                value={formData.new_password}
                name="new_password"
                id="new_password"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={"auto"} md={2} className="align-self-center">
              <span className="fontLight font-size03 colorGray01 wh-sp">
                ยืนยันรหัสผ่านใหม่
              </span>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="password"
                className="inp-login fontLight font-size03"
                value={formData.confirm_pass}
                name="confirm_pass"
                id="confirm_pass"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={6} className="d-flex justify-content-start">
              <button
                className="btn-cancel m-1 mt-5 flex-1"
                onClick={() => {
                  navigate(navigate_url);
                }}
              >
                ยกเลิก
              </button>{" "}
              <button
                className="btn-save m-1 mt-5"
                onClick={() => submitChangePassword()}
              >
                {isLoading ? "กำลังบันทึก..." : "บันทึก"}
              </button>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withLoading(UserChangePassword);
