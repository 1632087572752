import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { login, checkToken, forgotPassword } from "../api/auth.api"; // Assume you have forgotPassword in your API
import EnumRoles from "../constants/EnumRoles";
import "./Login.css";
import {
  showSuccessDialog,
  showErrorDialog,
  showPasswordRecoveryDialog,
} from "../components/Dialog";
import passwordIcon from "../assets/icons/ic_password.png";
import userIcon from "../assets/icons/ic_user.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      (async () => {
        try {
          const response = await checkToken(token);
          if (response.isValid) {
            let path = "/";
            if (response.user?.role === EnumRoles.LEARNER) {
              path = "/myclass";
            } else if (response.user?.role === EnumRoles.TEACHER) {
              path = "/student-dashboard";
            }
            navigate(path);
          } else {
            localStorage.removeItem("token");
            localStorage.removeItem("refreshToken");
          }
        } catch (error) {
          console.error("Error checking token:", error);
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
        }
      })();
    }
  }, [navigate]);

  const handleLogin = async () => {
    if (!email || !password) {
      showErrorDialog("กรุณากรอกข้อมูลให้ครบถ้วน");
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await login(email, password);

      if (response.status === "success") {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("refreshToken", response.data.refreshToken);

        let path = "/";
        if (response.data?.role === EnumRoles.LEARNER) {
          path = "/myclass";
        } else if (response.data?.role === EnumRoles.TEACHER) {
          path = "/student-dashboard";
        }

        showSuccessDialog("เข้าสู่ระบบ สำเร็จ").then(navigate(path));
      } else {
        showErrorDialog(response.message);
      }
    } catch (error) {
      showErrorDialog(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleForgotPassword = async () => {
    const result = await showPasswordRecoveryDialog();

    if (result.isConfirmed) {
      const usernameOrEmail = result.value;

      try {
        await forgotPassword(usernameOrEmail);
        showSuccessDialog("รหัสผ่านใหม่ถูกส่งไปยังอีเมลที่ลงทะเบียนไว้");
      } catch (error) {
        showErrorDialog("เกิดข้อผิดพลาด ไม่สามารถส่งรหัสผ่านใหม่ได้");
      }
    }
  };

  return (
    <div className="login-container fontReg">
      <div className="login-box">
        <h2>เข้าสู่ระบบ</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleLogin();
          }}
        >
          <div className="form-group">
            <div className="input-group">
              <input
                type="email"
                id="email"
                className="form-control"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                aria-label="Email"
                placeholder="รหัสผู้ใช้งาน หรือ อีเมล"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <img
                    src={userIcon}
                    alt="User Icon"
                    style={{ width: "20px" }}
                  />{" "}
                  {/* Your custom user icon */}
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <div className="input-group">
              <input
                type="password"
                id="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                aria-label="Password"
                placeholder="รหัสผ่าน"
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <img
                    src={passwordIcon}
                    alt="Password Icon"
                    style={{ width: "20px" }}
                  />{" "}
                  {/* Your custom password icon */}
                </span>
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={isSubmitting}
          >
            {isSubmitting ? "เข้าสู่ระบบ..." : "เข้าสู่ระบบ"}
          </button>
          <div className="forgot-password">
            <button
              type="button"
              className="btn btn-link"
              onClick={handleForgotPassword}
            >
              ลืมรหัสผ่าน?
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
