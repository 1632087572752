import React, {useState} from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {
  const location = useLocation();

  const [isSidebarCollapsed, setSidebarCollapsed] = useState(false);

  const toggleSidebar = () => {
    setSidebarCollapsed(prevState => !prevState);
  };

  const menuItems = [
    { path: '/', label: 'แดชบอร์ด', code: 'dashboard' },
    { path: '/institutions',label: 'สถาบัน', code: 'institution_manage' },
    { path: '/institutions/action',label: 'สถาบัน', code: 'institution_manage' },
    { path: '/licenses', label: 'ใบอนุญาต', code: 'licence_manage' },
    { path: '/licenses/action', label: 'ใบอนุญาต', code: 'licence_manage' },
    { path: '/teacher-user', label: 'จัดการผู้สอน', code: 'teacher_manage' },
    { path: '/teacher-user/import', label: 'ผู้สอน', code: 'teacher_manage_import' },
    { path: '/learner-user', label: 'จัดการผู้เรียน', code: 'learner_manage' },
    { path: '/learner-user/import', label: 'จัดการผู้เรียน', code: 'learner_manage' },
    { path: '/modelvr', label: 'แบบจำลองเสมือนจริง', code: 'modelvr_manage' },
    { path: '/modelvr/action', label: 'แบบจำลองเสมือนจริง', code: 'modelvr_manage' },
    { path: '/certificate', label: 'ใบรับรอง', code: 'certificate_manage' },
    { path: '/certificate/action', label: 'ใบรับรอง', code: 'certificate_manage' },
    { path: '/myclass', label: 'ห้องเรียนของฉัน', code: 'learner_class' },
    { path: '/reward-redeem', label: 'ประวัติแลกคะแนน', code: 'reward_history' },
    { path: '/classmanage', label: 'จัดการห้องเรียน', code: 'teacher_class_manage' },
    { path: '/classmanage/action', label: 'สร้างห้องเรียน', code: 'teacher_class_manage' },
    { path: '/rewardmanage', label: 'ของรางวัล', code: 'teacher_reward_manage' },
    { path: '/reward-category', label: 'ประเภทของรางวัล', code: 'teacher_reward_manage' },
    { path: '/student-dashboard', label: 'จัดอันดับคะแนนนักเรียน', code: 'student_dashboard' },
    { path: '/test-manage', label: 'แบบทดสอบ', code: 'test_manage' },
    { path: '/update-user', label: 'แก้ไขข้อมูล', code: 'update_user' },
    { path: '/changepassword', label: 'เปลี่ยนรหัสผ่าน', code: 'changepassword' },
    // Add more items if needed
  ];

  const currentMenuItem = menuItems.find(item => item.path === location.pathname);

  return (
    <div className="wrapper fontReg">
       <Header onToggleSidebar={toggleSidebar} isSidebarCollapsed={isSidebarCollapsed}/>
        <Sidebar collapsed={isSidebarCollapsed}/>

      <div className={`content-wrapper ${isSidebarCollapsed ? 'collapsed' : ''}`}>
      <div className="header-title">
        {currentMenuItem ? currentMenuItem.label: "จัดการข้อมูล"}
      </div>
        
          <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
