import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import headerDefinitions from "../tableheaders/reward.tableheader.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import helpers from "../helpers/function.helper";
import LoadingComponent from "../components/Loading";
import { getList } from "../api/reward.api";
import Swal from "sweetalert2";
import viewIcon from "../assets/icons/view.png";
import editIcon from "../assets/icons/edit.png";
import formIcon from "../assets/icons/form.png";
import downloadIcon from "../assets/icons/download.png";
import deleteIcon from "../assets/icons/delete.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import TableHeaderButton from "../components/TableHeaderButton";

const TeacherRewardManage = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [isLoading, setLoading] = useState(true);

  // -------------------- Data Table ----------------------------------------
  const headersData = headerDefinitions.columns;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataTable, setDatatable] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const tableData = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => headersData, [headersData]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useSortBy,
      usePagination
    );

  const toggleRowSelection = (Id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(Id)) {
        // If already selected, remove it
        return prev.filter((id) => id !== Id);
      } else {
        // Otherwise, add it
        return [...prev, Id];
      }
    });
  };

  const handleSort = async (columnId) => {
    // Will Sort By *accessor* in HeaderJSON File
    setSortConfig((prevSortConfig) => {
      let direction = "ASC";
      if (
        prevSortConfig.key === columnId &&
        prevSortConfig.direction === "ASC"
      ) {
        direction = "DESC";
      }
      return { key: columnId, direction };
    });
  };

  const handleNextPage = () => {
    setPageIndex((current) => {
      const nextPageIndex = current + 1;
      setCanNextPage(nextPageIndex < pageCount - 1);
      setCanPreviousPage(true);
      return nextPageIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((current) => {
      const previousPageIndex = Math.max(0, current - 1);
      setCanPreviousPage(previousPageIndex > 0);
      setCanNextPage(true);
      return previousPageIndex;
    });
  };

  // -------------------- Data Table ----------------------------------------

  const clearSearch = () => {
    setKeyword("");
    setSortConfig({ key: "", direction: "" });
    setRowsPerPage(10);
    setPageIndex(0);
  };

  const fetchData = async () => {
    console.log("Fetching data...");
    try {
      const { data, totalPages } = await getList({
        page_number: pageIndex,
        data_per_page: rowsPerPage,
        keyword: keyword,
        sort_key: sortConfig.key,
        sort_order: sortConfig.direction,
      });
      if (data) {
        console.log("Data received:", data);
        setDatatable(data);
        setPageCount(totalPages);
        setCanNextPage(pageIndex < Math.ceil(totalPages / rowsPerPage) - 1);
        setCanPreviousPage(pageIndex > 0);
      }
    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleExportData = async () => {
    const swalWithBootstrapButtons = Swal.mixin({}); // Providing an empty object as argument

    let res = await swalWithBootstrapButtons.fire({
      title: "คุณต้องการที่ดาวน์โหลด ใช่หรือไม่",
      icon: "warning",
      confirmButtonText: "ตกลง",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
      showCancelButton: true,
    });
    if (res.isConfirmed) {
      try {
        setLoading(true);
        window.open(
          `${process.env.REACT_APP_BASE_URL}/institution/export`,
          "_blank"
        );
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
      <div className="container-fluid">
        <div className="box-white">
          <Row className="mb-3">
            <h2>จัดการรางวัล</h2>
            <Col md={"auto"}>
              <button
                className="custom-btn btn-create mx-1"
                onClick={() => navigate("/rewardmanage/action?method=create")}
              >
                <FontAwesomeIcon icon={faPlus} />{" "}
                <span className="mx-1">สร้าง</span>
              </button>
            </Col>
            {/* <Col md={"auto"}>
              <button className="custom-btn btn-secondary-custom mx-1">
                <img src={formIcon} alt="Form" className="icon" />{" "}
                <span className="mx-1">แบบฟอร์ม</span>
              </button>
            </Col>
            <Col md={"auto"}>
              <button
                className="custom-btn btn-secondary-custom mx-1"
                onClick={() => handleExportData()}
              >
                <img src={downloadIcon} alt="Download" className="icon" />{" "}
                <span className="mx-1">ดาวน์โหลด</span>
              </button>
            </Col>
            <Col md={6} /> */}
          </Row>
          <div className="d-flex justify-content-between align-items-center mx-2 py-3">
            <div className='d-none d-md-block'>
              <span className="pr-2 align-self-center text-sm fw-bold">
                Show :{" "}
              </span>
              <label>
                <select
                  style={{ border: "none", cursor: "pointer" }}
                  className="select-page mr-2"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </select>
              </label>
              <label>Entries</label>
            </div>
            <div className="d-flex search-container">
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="ค้นหา"
                className="search-input"
              />
              <button className="search-button" onClick={fetchData}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
          <div style={{ overflow: "auto" }}>
            <Table
              striped
              bordered
              {...getTableProps()}
              className="table text-center "
              style={{ borderCollapse: "collapse" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                        {/* Add a clickable sort icon */}
                        <TableHeaderButton
                          column={column}
                          sortConfig={sortConfig}
                          handleSort={handleSort}
                        />
                      </th>
                    ))}
                    <th>เครื่องมือ</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {" "}
                <tr className="space-table"></tr>
                {rows.length > 0 ? (
                  rows.map((row, rowIndex) => {
                    prepareRow(row);
                    const actualIndex = pageIndex * rowsPerPage + rowIndex + 1;
                    const isSelected = selectedRowIds.includes(row.original.id);
                    return (
                      <tr
                        {...row.getRowProps()}
                        onClick={() => toggleRowSelection(row.original.id)}
                        className={isSelected ? "selected-row" : ""}
                        style={isSelected ? { backgroundColor: "#f0f0f0" } : {}}
                      >

                        <td>{row.original?.rew_id ?? ""}</td>
                        <td>{row.original?.rew_name ?? ""}</td>
                        <td>{row.original?.reward_category?.rec_name ?? ""}</td>
                        <td>{row.original?.rew_point ?? ""}</td>

                        <td>
                          <span
                            style={{
                              color: row.original.is_active
                                ? "#87BD66"
                                : "#D95350",
                            }}
                          >
                            {helpers.getStatus(row.original.is_active)}
                          </span>
                        </td>
                        <td>
                          <button
                            className="mx-1 icon-btn"
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              navigate(`/rewardmanage/action/${row.original.rew_id}?method=view`);
                            }}
                          >
                            <img
                              src={viewIcon}
                              alt="View"
                              className="ic-actions"
                            />
                          </button>

                          <button
                            className="mx-1 icon-btn"
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              navigate(`/rewardmanage/action/${row.original.rew_id}?method=edit`);
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="Edit"
                              className="ic-actions"
                            />
                          </button>

                          <button
                            className="mx-1 icon-btn"
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              navigate(``);
                            }}
                          >
                            <img
                              src={deleteIcon}
                              alt="Edit"
                              className="ic-actions"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan={100} style={{ textAlignLast: "center" }}>
                      ไม่พบข้อมูล
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div
            className="py-3 mx-2"
            style={{ display: "flex", justifyContent: "right" }}
          >
            {/* Pagination */}
            <div className="mt-2">
              <span>
                {`${pageIndex * rowsPerPage + 1} - ${
                  (pageIndex + 1) * rowsPerPage
                } จาก ${pageCount} `}
              </span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handlePreviousPage}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <span> {pageIndex + 1}</span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handleNextPage}
                disabled={!canNextPage}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
              <span>{Math.ceil(pageCount / rowsPerPage)}</span>
            </div>
          </div>
        </div>
      </div>
  );
};

export default TeacherRewardManage;
