import Swal from "sweetalert2";
import './Dialog.css'
import withReactContent from "sweetalert2-react-content";
import logoutLogo from "../assets/icons/img_logo_logout.png";
import successLogo from "../assets/icons/img_success.png";
import warningLogo from "../assets/icons/img_warning.png";
import folderLogo from "../assets/icons/img_folder.png";
import binLogo from "../assets/icons/img_delete.png";
import RewardIcon from "../assets/icons/swalReward.svg";
import passwordLogo from "../assets/icons/img_password.png";

const MySwal = withReactContent(Swal);

// Logout Dialog
export const showLogoutDialog = () => {
  return MySwal.fire({
    html: `
      <div style="text-align: center; font-family: 'db_heaventregular';">
        <img src="${logoutLogo}" alt="Logout Icon" style="width: 100px; height: auto;" />
        <h2>ออกจากระบบ</h2>
        <p>คุณต้องการออกจากระบบใช่ หรือ ไม่</p>
      </div>
    `,
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';" >ใช่</span>`,
    cancelButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';" >ยกเลิก</span>`,
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn-save mx-2",
      cancelButton: "btn-cancel mx-2",
    },
  });
};

// Success Dialog
export const showSuccessDialog = (message) => {
  return MySwal.fire({
    html: `
      <div style="text-align: center; font-family: 'db_heaventregular';">
        <img src="${successLogo}" alt="Logout Icon" style="width: 100px; height: auto;" />
        <h2>สำเร็จ</h2>
        <p>${message}</p>
      </div>
    `,
    showCloseButton: false,
    showConfirmButton: false
  });
};

// Warning/Error Dialog
export const showErrorDialog = (message) => {
  return MySwal.fire({
    html: `
      <div style="text-align: center; font-family: 'db_heaventregular';">
               <img src="${warningLogo}" alt="Logout Icon" style="width: 100px; height: auto;" />
        <h2>ข้อมูลผิดพลาด</h2>
        <p>${message}</p>
      </div>
    `,
    confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ปิด</span>`,
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn-save mx-2",
    },
  });
};

// Delete Confirmation Dialog
export const showDeleteDialog = () => {
  return MySwal.fire({
    html: `
      <div style="text-align: center; font-family: 'db_heaventregular';">
               <img src="${binLogo}" alt="Logout Icon" style="width: 100px; height: auto;" />
        <h2>ลบข้อมูล</h2>
        <p>คุณแน่ใจหรือไม่ว่าจะลบข้อมูลนี้</p>
      </div>
    `,
    showCancelButton: true,
    reverseButtons: true,
    confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ตกลง</span>`,
    cancelButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ยกเลิก</span>`,
    buttonsStyling: false,
    customClass: {
      confirmButton: "btn-save mx-2",
      cancelButton: "btn-cancel mx-2",
    },
  });
};

export const showEditDialog = () => {
    return MySwal.fire({
      html: `
        <div style="text-align: center; font-family: 'db_heaventregular';">
            <img src="${folderLogo}" alt="Logout Icon" style="width: 100px; height: auto;" />
          <h2>แก้ไขข้อมูล</h2>
          <p>คุณต้องการแก้ไข ใช่ หรือ ไม่</p>
        </div>
      `,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ตกลง</span>`,
      cancelButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ยกเลิก</span>`,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-save mx-2",
        cancelButton: "btn-cancel mx-2",
      },
    });
  };

  export const showCreateDialog = () => {
    return MySwal.fire({
      html: `
        <div style="text-align: center; font-family: 'db_heaventregular';">
            <img src="${folderLogo}" alt="Logout Icon" style="width: 100px; height: auto;" />
          <h2>บันทึก</h2>
          <p>คุณต้องการบันทึกใช่ หรือ ไม่</p>
        </div>
      `,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ตกลง</span>`,
      cancelButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ยกเลิก</span>`,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-save mx-2",
        cancelButton: "btn-cancel mx-2",
      },
    });
  };

  export const showRedeemDialog = () => {
    return MySwal.fire({
      html: `
        <div style="text-align: center; font-family: 'db_heaventregular';">
          <img src="${RewardIcon}" alt="Logout Icon" style="width: 100px; height: auto;" />
          <h2>แลกของรางวัล</h2>
          <p>คุณต้องการแลกของรางวัลใช่ หรือ ไม่</p>
        </div>
      `,
      showCancelButton: true,
      reverseButtons: true, // This swaps the sides of the confirm and cancel buttons
      confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ตกลง</span>`,
      cancelButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ยกเลิก</span>`,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-save mx-2",
        cancelButton: "btn-cancel mx-2",
      },
    })
  }

  export const showDownloadDialog = () => {
    return MySwal.fire({
      html: `
        <div style="text-align: center; font-family: 'db_heaventregular';">
          <img src="${folderLogo}" alt="Logout Icon" style="width: 100px; height: auto;" />
          <h2>ดาวโหลด</h2>
          <p>คุณต้องการดาวโหลดไฟล์ใช่ หรือ ไม่</p>
        </div>
      `,
      showCancelButton: true,
      reverseButtons: true, // This swaps the sides of the confirm and cancel buttons
      confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ตกลง</span>`,
      cancelButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ยกเลิก</span>`,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn-save mx-2",
        cancelButton: "btn-cancel mx-2",
      },
    })
  }

  export const showPasswordRecoveryDialog = async () => {
    return MySwal.fire({
      html: `
        <div style="text-align: center; font-family: 'db_heaventregular'; width: 100%;">
          <img src="${passwordLogo}" alt="Lock Icon" style="width: 50px; height: auto; margin-bottom: 20px;" />
          <h2>ลืมรหัสผ่าน</h2>
          <p>กรุณากรอกอีเมลเพื่อรีเซ็ตรหัสผ่าน<br/>รหัสผ่านใหม่จะถูกส่งไปยังอีเมลที่ลงทะเบียนไว้ในระบบ</p>
          <input 
            type="text" 
            id="username-email" 
            placeholder="ชื่อผู้ใช้หรืออีเมล" 
            style="width: 100%; box-sizing: border-box; margin-top: 20px;" 
          />
        </div>
      `,
      showCancelButton: true,
      reverseButtons: true,
      confirmButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ตกลง</span>`,
      cancelButtonText: `<span style="padding: 0 16px; font-family: 'db_heaventregular';">ยกเลิก</span>`,
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn-save mx-2',
        cancelButton: 'btn-cancel mx-2',
      },
      preConfirm: () => {
        const usernameOrEmail = (document.getElementById('username-email')).value;
        if (!usernameOrEmail) {
          Swal.showValidationMessage('กรุณากรอกอีเมล');
        }
        return usernameOrEmail;
      },
    });
  };