import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { create, edit, getByID, getClassChapterByID, getQuestionList } from "../api/classlesson.api";
import Select from "react-select";
import { showCreateDialog, showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";
import './TeacherClassLessonAction.css'


const TeacherClassLessonAction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const u_method = urlParams.get("method");
  const section_id = urlParams.get("cls_id");
  const classroom_id = urlParams.get("cla_id");
  const chapter_id = urlParams.get("clc_id");

  const [method, setMethod] = useState(u_method);
  const [chapter, setChapter] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();

  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  
  const [lessonOptions, setLessonOptions] = useState([
    {
      label: "ทดสอบก่อนเรียน",
      value: "pretest",
    },
    {
      label: "เนื้อหา",
      value: "learn",
    },
    {
      label: "ทดสอบหลังเรียน",
      value: "posttest",
    },
    {
      label: "การบ้าน",
      value: "homework",
    },
  ]);

  const [vrOptions, setVROptions] = useState([
    {
      label: "Question",
      value: "question",
    },
    {
      label: "Content",
      value: "content",
    },
    {
      label: "VR Interaction",
      value: "vr-interaction",
    },
  ]);
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    type:'',
    timer:'',
    round: '',
    point: '',
    no: '',
    group: '',
    content: '',
    classroom_id,
    section_id,
    chapter_id,
    is_active:true
  });

  const moveToRight = () => {
    const selectedItems = Array.from(document.querySelectorAll('#leftList option:checked')).map(option => option.value);
    const newItems = selectedItems
      .map(id => leftItems.find(item => item.que_id === id))
      .filter(item => item && !rightItems.includes(item)); // Avoid duplicates
    setRightItems([...rightItems, ...newItems]);
  };

  const moveToLeft = () => {
    const selectedItems = Array.from(document.querySelectorAll('#rightList option:checked')).map(option => option.value);
    setRightItems(rightItems.filter(item => !selectedItems.includes(item.que_id)));
  };

  // Double-click functions
  const handleDoubleClickRight = (item) => {
    if (!rightItems.some(rightItem => rightItem.que_id === item.que_id)) {
      setRightItems([...rightItems, item]); // Only add if not already present
    }
  };

  const handleDoubleClickLeft = (item) => {
    setRightItems(rightItems.filter(rightItem => rightItem.que_id !== item.que_id)); // Remove from rightItems
  };


  const isFormValid = () => {
    const {
      name,
      type,
      timer,
      round,
      point,
      no,
      group,
    } = formData;
  // Check if required fields are filled
  console.log(formData)
  if (!name || !type || !timer || !round || !point || !no || !group ) {
    showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน');
    return false;
  }

  // If all fields are valid
  return true;
};

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleIsActiveChange = (e) => {
    setFormData({ ...formData, is_active: e.target.value === 'true' });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  }

  const fetchData = async () => {
    try {
      setLoading(true)
        if(id){
          const response = await getByID(id);
          if(response){
            // setSection(response)
            setFormData({
              id: response?.cll_id,
              name: response?.cll_name,
              type: response?.cll_type,
              timer: response?.cll_timer,
              round: response?.cll_round,
              point: response?.cll_point,
              group: response?.cll_group,
              no: response?.cll_order,
              content: response?.cll_content,
              chapter_id: chapter_id,
              is_active: response?.is_active
            })

            setRightItems(response?.questions)
          // // Find the option that matches the mvr_type
          // const matchedOption = vrOptions.find(
          //   (option) => option.value === response?.cll_type
          // );
  
          // // Set the selected option if a match is found
          // setSelectedVROption(matchedOption || null);

          // const matchedGroup = lessonOptions.find(
          //   (option) => option.value === response?.cll_group
          // )
          // setSelectedLessonOption(matchedGroup)
          }else{
            showErrorDialog('ไม่พบข้อมูล Lesson').then(async (result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
              }
            });
          }
        }

        const questions = await getQuestionList()
        if(questions?.data){
          setLeftItems(questions?.data)
        }
        const chapter = await getClassChapterByID(chapter_id)
        if(chapter){
          setChapter(chapter)
        }else{
          showErrorDialog('ไม่พบข้อมูล Chapter').then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
            }
          });
        }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
        }
      });
    }
  };

  const createClassLessonData = async () => {
    formData.questions = rightItems
    if (!isFormValid()) {
      return;
    }

    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);

      const response = await create(formData);
      if (response) {
        await showSuccessDialog('เพิ่มข้อมูลสำเร็จ')
          navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
        }
      });
    } finally {
      setLoading(false)
    }
  }

  const updateClassLessonData = async (id) => {
    if (!isFormValid()) {
      return;
    }

    try {

      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true)
      const response = await edit(id, formData);
        if (response) {
          showSuccessDialog('แก้ไขข้อมูลสำเร็จ')
          navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
        }else{
          showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
            }
          });
        }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
        }
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
    }

    fetchData();

    if (method === "view") {
      setIsView(true);
    }

  }, []);

  return (
      <div className="container-fluid">
        <div className="box-white">
          <div className="mt-3">
            <h4 className="px-3 mb-2">จัดการเนื้อหา</h4>
              <h5 className="px-3 mb-2 text-header">จัดการเนื้อหา {chapter ? `| บทเรียนย่อย ${chapter.clc_name}`: ''}</h5>

            <Row className="mt-3 px-5">
              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ชื่อเนื้อหา
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.name}
                  name="name"
                  id="name"
                  onChange={handleChange}
                />
              </Col>

              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                ประเภทเนื้อหา
                </span>
              </Col>
              <Col xs={12} md={4}>
                <Select
                  isDisabled={isView}
                  name="group"
                  value={lessonOptions.find(
                    (option) => option.value === formData.group
                  )}
                  options={lessonOptions}
                  onChange={handleSelectChange}
                  placeholder="Please select"
                />
              </Col>


            </Row>

            <Row className="mt-3 px-5">
              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  จับเวลา
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}

                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.timer}
                  name="timer"
                  id="timer"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    const key = event.key;
                    if (
                      !/^\d$/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Delete"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>

              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                เริ่มใหม่
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}

                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.round}
                  name="round"
                  id="round"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    const key = event.key;
                    if (
                      !/^\d$/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Delete"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5">
              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  คะแนน
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}

                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.point}
                  name="point"
                  id="point"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    const key = event.key;
                    if (
                      !/^\d$/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Delete"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>

              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                ลำดับ
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  disabled={isView}

                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.no}
                  name="no"
                  id="no"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    const key = event.key;
                    if (
                      !/^\d$/.test(key) &&
                      key !== "Backspace" &&
                      key !== "Delete"
                    ) {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5 align-self-center">
              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                รูปแบบเนื้อหา
                </span>
              </Col>
              <Col xs={12} md={4}>
                <Select
                  isDisabled={isView}
                  name="type"
                  value={vrOptions.find(
                    (option) => option.value === formData.type
                  )}
                  options={vrOptions}
                  onChange={handleSelectChange}
                  placeholder="Please select"
                />
              </Col>
            </Row>

            {formData.type === 'content' ? 
                (<Row className="mt-3 px-5">
                  <Col xs={'auto'} md={1} className="align-self-center">
                    <span className="fontLight font-size03 colorGray01 wh-sp">
                      Content
                    </span>
                  </Col>
                  <Col xs={12} md={4}>
                    <textarea
                      rows={3}
                      cols={50}
                      disabled={isView}
                      type="text"
                      className="fontLight font-size03"
                      value={formData.content}
                      name="content"
                      id="content"
                      onChange={handleChange}
                    />
                  </Col>
                </Row>)
                : 
                (<></>)
              } 

              {formData.type === 'question' ? 
                (<Row className="mt-3 px-5">
                  <Col xs={'auto'} md={1} className="align-self-center">
                    <span className="fontLight font-size03 colorGray01 wh-sp">
                      คำถาม
                    </span>
                  </Col>
                  <Col xs={10} md={10}>
                  <div className="dual-listbox">
                    <div className="list-container">
                      <div className="lesson-select-header">คำถาม</div>
                      <select 
                      className="lesson-select" 
                      id="leftList" 
                      multiple
                      disabled={isView}
                      >
                        {leftItems.map((item, index) => (
                        <option
                          key={item.que_id ?? index + 1}
                          value={item.que_id}
                          onDoubleClick={() => handleDoubleClickRight(item)}
                        >
                          {item.que_name}
                        </option>
                        ))}
                      </select>
                    </div>

                    <div className="lesson-controls">
                      <button className="lesson-button" onClick={moveToRight}>&gt;&gt;</button>
                      <button className="lesson-button" onClick={moveToLeft}>&lt;&lt;</button>
                    </div>

                    <div className="list-container">
                    <div className="lesson-select-header">เลือกคำถามคำถาม</div>
                      <select 
                      className="lesson-select" 
                      id="rightList" 
                      multiple
                      disabled={isView}
                      >
                        {rightItems.map((item, index) => (
                          <option 
                          key={item.que_id ?? index + 1}
                            value={item.que_id}
                            onDoubleClick={() => handleDoubleClickLeft(item)}
                          >
                            {item.que_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  </Col>
                </Row>)
                : 
                (<></>)
              } 

            <Row className="mt-3 px-5 align-self-center">
              <Col xs={'auto'} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  สถานะ {" "}
                </label>
              </Col>

              <Col sm={12} md={4} className="align-self-center px-5">
                <p className="wh-sp">
                  <Form.Check
                    disabled={isView}
                    className="px-5"
                    inline
                    type="radio"
                    label="ใช้งาน"
                    name="isActiveGroup"
                    id="Active"
                    value="true"
                    checked={formData.is_active === true}
                    onChange={handleIsActiveChange}
                  />
                  <Form.Check
                  disabled={isView}
                    inline
                    type="radio"
                    label="ไม่ใช้งาน"
                    name="isActiveGroup"
                    id="Inactive"
                    value="false"
                    checked={formData.is_active === false}
                    onChange={handleIsActiveChange}
                  />
                </p>
              </Col>
            </Row>
              

            <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate(`/chapter/lesson/${chapter_id}?cls_id=${section_id}&cla_id=${classroom_id}`);
                    
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createClassLessonData()
                        : updateClassLessonData(id);
                    }}
                    // disabled={!isFormValid()}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
  );
};

export default TeacherClassLessonAction;
