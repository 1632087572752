import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getClassroomByID = async (id) => {
    try {
        const response = await instance.get(`/classroom/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const getClassSectionByID = async (id) => {
  try {
      const response = await instance.get(`/classsection/${id}`);
      if(response.status === 200){
          return response.data.data;
      }else{
          return null
      }
    } catch (error) {
      return null
    }
};

export const getTeacherList = async (query)=> {
  try {
      const response = await instance.get(`/teacher`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const getCertificateList = async (query)=> {
  try {
      const response = await instance.get(`${API_BASE_URL}/themecertificate`,{ params: query });

      if(response.status === 200){
          const data = response.data.data || [];
          const totalPages = response.data.total;
          return { data, totalPages };
      }else{
          return null
      }

    } catch (error) {
      console.log(error)
      return null
    }
};

export const getList = async (query)=> {
    try {
        const response = await instance.get(`/classsection`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`/classsection/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (data) => {
    try {
        const toCreate = {
          cls_no: data?.no,
          cls_name: data?.name,
          classroom_id: data?.classroom_id,
          is_active: data?.is_active,
          template_id: data?.theme,
        }

        const response = await instance.post(`/classsection`,toCreate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const edit = async (id,data)=> {
    try {
        const toUpdate = {
            cls_no: data?.no,
            cls_name: data?.name,
            classroom_id: data?.classroom_id,
            is_active: data?.is_active,
            template_id: data?.theme,
        }
        const response = await instance.put(`/classsection/${id}`,toUpdate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

