import React from 'react'
import Select from "react-select"

const DropdownSearch = ({ name, value, handler, options, placeholder, ...props }) => {
    return (
        <>
            <Select
                name={name}
                defaultValue={value}
                value={options.find(option => option.value === value)}
                onChange={(e) => handler(e)}
                options={options}
                placeholder={placeholder ?? 'Please select'}
                {...props}
            />
        </>
    )
}

export default DropdownSearch