import React, { useState } from "react";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import './ExcelRenderer.css'
import { showErrorDialog } from "./Dialog";

const ImportExcel = ({ onImport, datDetailExcel = () => {}, dtStatusClear }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileName, setFileName] = useState(null);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = e.dataTransfer.files;
    if (files && files[0]) {
      handleFile(files[0]);
    }
  };

  const handleFile = (file) => {
    const mimeType = file.type;
    if (
      mimeType !== "application/vnd.ms-excel" &&
      mimeType !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      showErrorDialog('กรุณาตรวจสอบข้อมูล ไฟล์ต้องเป็น excel เท่านั้น')
      return;
    } else {
      setFileName(file.name);

      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "binary" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        onImport(rows);
      };
      reader.readAsBinaryString(file);

      datDetailExcel(file.name); // Ensure this function is defined or use a no-op
    }
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = ".xlsx,.xls";
    input.onchange = (e) => {
      const file = e.target.files[0];
      handleFile(file);
    };
    input.click();
  };

  return (
    <>
      <div
        className={`dropzone-container ${isDragging ? "dragging" : ""} px-5 py-4`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        {dtStatusClear ? (
          <div className="dropzone-message">Upload File</div>
        ) : (
          <>
            {fileName ? (
              <div className="dropzone-message">{fileName}</div>
            ) : (
              <div className="dropzone-message">Upload File</div>
            )}
          </>
        )}
        <button className="select-file-button btn-upload">Choose file</button>
      </div>
      {/* <div className="col-12 mb-2 mb-lg-2 wh-sp-text">
        <span className="fs-12 fw-bold text-warning mb-1">
          รองรับไฟล์ .xlsx เท่านั้น &nbsp;
        </span>
        <span className="fs-12 fw-bold text-danger mb-1">
          (กรุณาดาวน์โหลดไฟล์ Template Form ตามไฟล์แนบ สำหรับ Upload File)
        </span>
      </div> */}
    </>
  );
};

export default ImportExcel;
