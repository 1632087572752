// ChartComponent.js
import React from 'react';
import './ChartComponent.css';

const ChartComponent = ({ title, placeholder, details }) => {
  return (
    <div className="chart">
      <h3>{title}</h3>
      <div className="chart-placeholder">{placeholder}</div>
      <div className="chart-details">
        {details.map((detail, index) => (
          <p key={index}>{detail.label} <span className="text-primary">{detail.value}</span></p>
        ))}
      </div>
    </div>
  );
};

export default ChartComponent;
