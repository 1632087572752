import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { getCertificateList, getClassroomByID, getClassSectionByID } from "../api/classsection.api";
import { create, edit, getByID } from "../api/classchapter.api";
import Select from "react-select";
import { showCreateDialog, showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";


const TeacherClassChapterAction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const u_method = urlParams.get("method");
  const section_id = urlParams.get("cls_id");
  const classroom_id = urlParams.get("cla_id");

  const [method, setMethod] = useState(u_method);
  const [classroomId, setClassroomId] = useState(classroom_id);
  const [sectionId, setSectionId] = useState(section_id);
  const [classroom, setClassroom] = useState(null);
  const [certificateOptions, setCertificateOptions] = useState([]);
  const [section, setSection] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    section_id: sectionId,
    is_active:true
  });

  const isFormValid = () => {
    const { name, section_id} = formData;
  
    // Ensure institution, count, and dates are filled in
    if (!name || !section_id) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน');
      return false;
    }
  
    // All validations passed
    return true;
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const handleIsActiveChange = (e) => {
    setFormData({ ...formData, is_active: e.target.value === 'true' });
  };

  const fetchData = async () => {
    try {
      setLoading(true)
        if(id){
          const response = await getByID(id);
          if(response){
            // setSection(response)
            setFormData({
              id: response.clc_id,
              name: response.clc_name,
              is_active: response.is_active
            })
          }else{
            showErrorDialog('ไม่พบข้อมูล Chapter').then(async (result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
              }
            });
          }
        }

        const section = await getClassSectionByID(section_id)
        if(section){
          setSection(section)
        }else{
          showErrorDialog('ไม่พบข้อมูล Section').then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
            }
          });
        }

    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
        }
      });
    }
  };

  const createClassChapterData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {

      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      const response = await create(formData);
      if (response) {

        await showSuccessDialog('เพิ่มข้อมูลสำเร็จ')
        navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
      } else {
        await showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
          }
        });
      }
    } catch (error) {
      await showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
        }
      });
    } finally {
      setLoading(false)
    }
  }

  const updateClassChapterData = async (id) => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true)
      const response = await edit(id, formData);
        if (response) {
          await showSuccessDialog('แก้ไขข้อมูลสำเร็จ')
          navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
        }else{
          showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
            }
          });
        }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
        }
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
    }

    fetchData();

    if (method === "view") {
      setIsView(true);
    }

  }, []);

  return (
      <div className="container-fluid">
        <div className="box-white">
          <div className="mt-3">
            <h4 className="px-3 mb-2">จัดการบทเรียนย่อย</h4>
              <h5 className="px-3 mb-2 text-header">จัดการบทเรียนย่อย {section ? `| บทเรียน ${section.cls_name}`: ''}</h5>

            <Row className="mt-3 px-5">
              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ชื่อบทเรียนย่อย
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.name}
                  name="name"
                  id="name"
                  onChange={handleChange}
                />
              </Col>
            </Row>

            <Row className="mt-3 px-5 align-self-center">
              <Col xs={'auto'} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  สถานะ {" "}
                </label>
              </Col>

              <Col sm={12} md={4} className="align-self-center px-5">
                <p className="wh-sp">
                  <Form.Check
                    className="px-5"
                    inline
                    type="radio"
                    label="ใช้งาน"
                    name="isActiveGroup"
                    id="Active"
                    value="true"
                    checked={formData.is_active === true}
                    onChange={handleIsActiveChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="ไม่ใช้งาน"
                    name="isActiveGroup"
                    id="Inactive"
                    value="false"
                    checked={formData.is_active === false}
                    onChange={handleIsActiveChange}
                  />
                </p>
              </Col>
            </Row>
              

            <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate(`/classsection/chapter?cls_id=${sectionId}&cla_id=${classroomId}`);
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createClassChapterData()
                        : updateClassChapterData(id);
                    }}
                    // disabled={!isFormValid()}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
  );
};

export default TeacherClassChapterAction;
