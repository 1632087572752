import React from 'react';
import './TableHeaderButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';


const TableHeaderButton = ({ column, sortConfig, handleSort }) => (
  <button
    onClick={() => handleSort(column.id)}
    style={{
      background: "none",
      border: "none",
      cursor: "pointer",
    }}
  >
    {sortConfig.key === column.id ? (
      sortConfig.direction === "ASC" ? (
        <FontAwesomeIcon icon={faSortUp} />
      ) : (
        <FontAwesomeIcon icon={faSortDown} />
      )
    ) : (
      <FontAwesomeIcon icon={faSort} />
    )}
  </button>
);

export default TableHeaderButton;
