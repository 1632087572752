import React from "react";
import "./Dashboard.css";
import ScorerCard from "../components/ScoreCard";
import ChartComponent from "../components/ChartComponent";

const Dashboard = () => {
  const chart1Details = [
    { label: 'ผู้เรียน', value: '40 คน' },
    { label: 'วิชา', value: 'รถยนต์ไฟฟ้า EV' },
    { label: 'คะแนน', value: '100%' },
  ];

  const chart2Details = [
    { label: 'แบบทดสอบ', value: '70%' },
    { label: 'การบ้าน', value: '70%' },
  ];

  return (
    <div className="content fontReg">
      <div className="container-fluid">
        <div className="box-white">
          <h2>จัดอันดับคะแนนนักเรียน</h2>
          <p>
            ปีที่ 1 ภาควิชา เครื่องยนต์ EV สาขา เครื่องยนต์ไฟฟ้าคณะวิศวะคอมพิวเตอร์
          </p>

          <div className="container-fluid p-0 m-0">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="top-scorers">
                  <div className="">
                    <ScorerCard rank="1" percentage="95" name="นายสิริชัย อัครวัฒน์" id="THA0001" points="100" />
                  </div>
                  <div className="">
                    <ScorerCard rank="1" percentage="95" name="นายสิริชัย อัครวัฒน์" id="THA0001" points="100" />
                  </div>
                  <div className="">
                    <ScorerCard rank="1" percentage="95" name="นายสิริชัย อัครวัฒน์" id="THA0001" points="100" />
                  </div>
                </div>

                <h2>อันดับคะแนนนักเรียนทั้งหมด</h2>
                <table className="student-scores">
                  <thead>
                    <tr>
                      <th>อันดับที่</th>
                      <th>รหัสผู้เรียน</th>
                      <th>ชื่อผู้เรียน</th>
                      <th>Point</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1</td>
                      <td>THA0001</td>
                      <td>นายสิริชัย อัครวัฒน์</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>2</td>
                      <td>THA0002</td>
                      <td>นายประนะ เจริญทรัพย์</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>3</td>
                      <td>THA0003</td>
                      <td>นายอัคริน จิตอาสา</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>4</td>
                      <td>THA0004</td>
                      <td>นายคำแหง อยู่ตรงดี</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>5</td>
                      <td>THA0005</td>
                      <td>นายสิริชัย ใจห้าวหาญ</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>6</td>
                      <td>THA0006</td>
                      <td>นางสาวกมลิกา ปานบิลกิบ</td>
                      <td>50</td>
                    </tr>
                    <tr>
                      <td>7</td>
                      <td>THA0007</td>
                      <td>นายอิสลาม อิกทิฟาล</td>
                      <td>50</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="col-lg-4 col-md-12">
              <div className="charts">
              <div className="charts">
              <ChartComponent
                title="คะแนนเฉลี่ยการเรียนรวมทั้งหมด"
                placeholder="Chart Placeholder"
                details={chart1Details}
              />
              <ChartComponent
                title="ลำดับคะแนนการทดสอบบทเรียน"
                placeholder="Chart Placeholder"
                details={chart2Details}
              />
            </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
