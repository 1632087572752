import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { getByID, create, edit } from "../api/license.api";
import { getInstitutionList } from "../api/license.api";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import withLoading from "../hoc/withLoading";
import { showCreateDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";

const LicenseAction = () => {
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [image, setImage] = useState([]);
  const [isValidDateRange, setIsValidDateRange] = useState(true);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [institutionData, setInstitutionData] = useState([]);
  const [institutionOptions, setInstitutionOptions] = useState([]);

  const [method, setMethod] = useState("");

  const [formData, setFormData] = useState({
    institution: "",
    count: "",
    ready: "", // react date picker
    expired: "", // react date picker
  });

  const isFormValid = () => {
    const { institution, count, ready, expired } = formData;
  
    // Ensure institution, count, and dates are filled in
    if (!institution || !count || !ready || !expired) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน');
      return false;
    }
  
    // Ensure ready date is before expired date
    if (new Date(ready) >= new Date(expired)) {
      showErrorDialog('วันที่เริ่มต้นต้องน้อยกว่าวันหมดอายุ');
      return false;
    }
  
    // All validations passed
    return true;
  };

  const fetchData = async () => {
    console.log("Fetching data...");
    try {
      const insData = await getInstitutionList({
        page_number: 0,
        data_per_page: 1000,
      });

      if (insData) {
        setInstitutionData(insData);

        const institutionOptions = insData.map((ins) => ({
          label: ins.ins_name,
          value: ins.ins_id,
        }));

        setInstitutionOptions(institutionOptions);
      }
    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleDateChange = (date, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: date,
    }));
  };


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const createLicensesData = async () => {
    if (!isFormValid()) {
      return;
    }

    try {

      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);

      const response = await create(formData);
      if (response) {
        await showSuccessDialog('เพิ่มข้อมูลสำเร็จ')
        navigate("/licenses");

      } else {
        await showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/licenses");
          }
        });
      }
    } catch (error) {
      await showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/licenses");
        }
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const method = urlParams.get("method");

    if (method !== "create") {
      navigate("/licenses");
    }

    setMethod(method);
    fetchData();
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h4 className="px-3 mb-2 wh-sp">ใบอนุญาต</h4>
          <h5 className="px-3 mb-2 text-header">เพิ่มใบอนุญาต</h5>
          <Row className="mt-3 px-5">
            <Col xs={4} md={1} className="align-self-center">
              <label className="fontLight font-size03 colorGray01 wh-sp">
                ชื่อสถาบัน
              </label>
            </Col>
            <Col xs={12} md={4}>
              <Select
                name="institution"
                value={institutionOptions.find(
                  (option) => option.value === formData.institution
                )}
                onChange={handleSelectChange}
                options={institutionOptions}
                placeholder="Please select"
                styles={{
                  control: (base) => ({
                    ...base,
                    width: "100%",
                    padding: "0",
                  }),
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 px-5">
            <Col xs={4} md={1} className="align-self-center">
              <label className="fontLight font-size03 colorGray01 wh-sp">
                จำนวนใบอนุญาต
              </label>
            </Col>
            <Col xs={12} md={4}>
              <input
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.count}
                name="count"
                id="count"
                onChange={handleChange}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (
                    !/^\d$/.test(key) &&
                    key !== "Backspace" &&
                    key !== "Delete"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
          </Row>
          <Row className="mt-3 px-5">
            <Col xs={4} md={1} className="align-self-center">
              <label className="fontLight font-size03 colorGray01 wh-sp">
                วันที่เริ่มต้น
              </label>
            </Col>
            <Col xs={12} md={4}>
              <ReactDatePicker
                style={{ display: "block" }}
                selected={formData.ready}
                onChange={(date) => handleDateChange(date, "ready")}
                disabled={method === "view"}
                dateFormat="dd/MM/yyyy"
                placeholderText="วัน/เดือน/ปี"
                className="form-control"
              />
            </Col>

            <Col xs={4} md={1} className="align-self-center">
              <label className="fontLight font-size03 colorGray01 wh-sp">
                วันที่หมดอายุ
              </label>
            </Col>
            <Col xs={12} md={4}>
              <ReactDatePicker
                style={{ display: "block" }}
                selected={formData.expired}
                onChange={(date) => handleDateChange(date, "expired")}
                disabled={method === "view"}
                dateFormat="dd/MM/yyyy"
                placeholderText="วัน/เดือน/ปี"
                className="form-control"
              />
            </Col>
          </Row>
          <Row className="mt-3 px-5">
            <Col xs={6} className="d-flex justify-content-start">
              <button
                className="btn-cancel m-1 mt-5 flex-1"
                onClick={() => {
                  navigate("/licenses");
                }}
              >
                ยกเลิก
              </button>{" "}
              {!isView ? (
                <button
                  className="btn-save m-1 mt-5"
                  onClick={(e) => {
                    createLicensesData();
                  }}
                  // disabled={!isFormValid()}
                >
                  บันทึก
                </button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withLoading(LicenseAction);
