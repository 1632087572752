import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  showCreateDialog,
  showEditDialog,
  showErrorDialog,
  showSuccessDialog,
} from "../components/Dialog";
import { create, edit, getByID, getCateList } from "../api/reward.api";
import Select from "react-select";

const TeacherRewardManageAction = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const method = urlParams.get("method");
  const shouldNavigate = "/rewardmanage";

  const [isView, setIsView] = useState(false);
  const [rewardCategoryOptions, setRewardCategoryOptions] = useState([]);
  const [formData, setFormData] = useState({
    rew_name: "",
    rec_id: "",
  });

  const isFormValid = () => {
    const { rec_name, rec_id } = formData;
    // Check if required fields are filled
    if (!rec_name || !rec_id) {
      showErrorDialog("กรุณากรอกข้อมูลให้ครบถ้วน");
      return false;
    }

    // If all fields are valid
    return true;
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const createRewardData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await create(formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(shouldNavigate);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(shouldNavigate);
        }
      });
    }
  };

  const updateRewardData = async (id) => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return;
      }

      const response = await edit(id, formData);
      if (response) {
        showSuccessDialog("เพิ่มข้อมูลสำเร็จ");
        navigate(shouldNavigate);
      } else {
        showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(shouldNavigate);
          }
        });
      }
    } catch (error) {
      showErrorDialog("เกิดข้อผิดพลาด").then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(shouldNavigate);
        }
      });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getByID(id);
        if (response) {
          setFormData({
            rew_name: response?.rew_name,
            rec_id: response?.reward_category?.rec_id
          });
        } else {
          showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then(
            (result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(shouldNavigate);
              }
            }
          );
        }
      } catch (error) {
        showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then(
          (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(shouldNavigate);
            }
          }
        );
      }
    };

    const fetchCateData = async() => {
        try {
            const category = await getCateList();
            if(category){
                const categoryOptions = category.data.map(c => ({
                  label: c.rec_name, 
                  value: c.rec_id,
                }));
                setRewardCategoryOptions(categoryOptions)
            }else{
                showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then(
                    (result) => {
                      if (result.isConfirmed || result.isDismissed) {
                        navigate(shouldNavigate);
                      }
                    }
                  );
            }
          } catch (error) {
            showErrorDialog("เกิดข้อผิดพลาด ไม่พบข้อมูล Reward").then(
              (result) => {
                if (result.isConfirmed || result.isDismissed) {
                  navigate(shouldNavigate);
                }
              }
            );
          }
    }

    fetchCateData()

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate(shouldNavigate);
    }

    if (method === "view" || method === "edit") {
      fetchData();
    }

    if (method === "view") {
      setIsView(true);
    }
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <h4 className="px-3 mb-2">ของรางวัล</h4>
        <h5 className="px-3 mb-2 text-header">จัดการข้อมูล ของรางวัล</h5>

        <Row className="mt-3 px-5">
          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ชื่อของรางวัล
            </span>
          </Col>
          <Col xs={12} md={4}>
            <input
              disabled={isView}
              type="text"
              className="inp-login fontLight font-size03"
              value={formData.rew_name}
              name="rew_name"
              id="rew_name"
              onChange={handleChange}
            />
          </Col>

          <Col xs={"auto"} md={1} className="align-self-center">
            <span className="fontLight font-size03 colorGray01 wh-sp">
              ประเภทของรางวัล
            </span>
          </Col>
          <Col xs={12} md={4}>
            <Select
              isDisabled={isView}
              name="rec_id"
              value={rewardCategoryOptions.find((option) => option.value === formData.rec_id)}
              options={rewardCategoryOptions}
              onChange={handleSelectChange}
              placeholder="Please select"
            />
          </Col>
        </Row>

        <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate(shouldNavigate);
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createRewardData()
                        : updateRewardData(id);
                    }}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>

      </div>
    </div>
  );
};
export default TeacherRewardManageAction;
