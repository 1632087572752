import React, {useState} from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import LearnerHeader from './LearnerHeader';
import LearnerFooter from './LearnerFooter';
import { Col, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LogoutIcon from '../assets/icons/swalLogout.svg';
import { showSuccessDialog, showLogoutDialog } from './Dialog';

const LearnerLayout = () => {
  
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    showLogoutDialog().then((result) => {
      if (result.isConfirmed) {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          navigate('/login');
          showSuccessDialog('ออกจากระบบสำเร็จ')
      }
    });
  };

  const menuItems = [
    { path: '/myclass', label: 'ห้องเรียนของฉัน', code: 'learner_class' },
    { path: '/reward-history', label: 'ประวัติแลกคะแนน', code: 'reward_history' },
    // Add more items if needed
  ];

  return (
    <div className="wrapper fontReg">
       <LearnerHeader/>

      <div className={`learner-content-wrapper`}>
      <div className="learner-header-title">
      <Row>
        <Col md={'1'} className='d-none d-md-block'>
          เมนู
        </Col>
        <Col sm={'auto'} md={'11'} className='ml-sm-0'>
          <span className='mx-2'>
            <button className={`learner-header-btn ${location.pathname === '/myclass' ? 'text-decoration-underline' : ''}`}
              onClick={() => navigate('/myclass')}
            >
              ห้องเรียนของฉัน
            </button>
          </span>
          <span className='mx-2'>
            <button className={`learner-header-btn ${location.pathname === '/reward-history' ? 'text-decoration-underline' : ''}`}
              onClick={() => navigate('/reward-history')}
            >
              ประวัติแลกของรางวัล
            </button>
          </span>
          <span className='mx-2' style={{float:'right'}}>
              <button className="learner-header-btn d-none d-md-block" onClick={handleLogout}>
                {/* <FontAwesomeIcon icon={faSignOutAlt} className="nav-icon" />  */}
                <span className="nav-text">ออกจากระบบ</span>
              </button>
          </span>
        </Col>
      </Row>
      </div>
        
          <Outlet />
      </div>
      <LearnerFooter />
    </div>
  );
};

export default LearnerLayout;
