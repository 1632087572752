import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { create, edit, getByID, getCertificateList, getClassroomByID } from "../api/classsection.api";
import Select from "react-select";
import { showCreateDialog, showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";


const TeacherClassSectionAction = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const u_method = urlParams.get("method");
  const classroom_id = urlParams.get("classroom_id");

  const [method, setMethod] = useState(u_method);
  const [classroomId, setClassroomId] = useState(classroom_id);
  const [classroom, setClassroom] = useState(null);
  const [certificateOptions, setCertificateOptions] = useState([]);
  const [section, setSection] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  

  const [formData, setFormData] = useState({
    id: "",
    no:"",
    name:"",
    classroom_id: classroomId,
    theme: "",
    is_active:true
  });

  const isFormValid = () => {
    const { no, name, theme } = formData;
  
    // Check if any of the fields are empty
    if (!name || !no || !theme) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน')
      return false;
    }

    return true;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  }

  const handleIsActiveChange = (e) => {
    setFormData({ ...formData, is_active: e.target.value === 'true' });
  };

  const fetchData = async () => {
    try {
      setLoading(true)
        if(id){
          const response = await getByID(id);
          if(response){
            // setSection(response)
            setFormData({
              id: response.cls_id,
              no: response.cls_no,
              name: response.cls_name,
              classroom_id: classroomId,
              theme: response?.themes_certificate?.thc_id,
              is_active: response.is_active
            })
          }else{
            showErrorDialog('ไม่พบข้อมูล Section').then(async (result) => {
              if (result.isConfirmed || result.isDismissed) {
                navigate(`/classmanage/classsection/${classroomId}`);
              }
            });
          }
        }
        const classroom = await getClassroomByID(classroomId)
        if(classroom){
          setClassroom(classroom)
        }else{
          showErrorDialog('ไม่พบข้อมูล Classroom').then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(`/classmanage/classsection/${classroomId}`);
            }
          });
        }

        const certificates = await getCertificateList()

        if(certificates){
          const certificateOptions = certificates.data.map(c => ({
            label: c.thc_id, 
            value: c.thc_id,
            name: c.thc_thumbnail,
            image: c.thc_image
          }));
          setCertificateOptions(certificateOptions)
        }else{
          showErrorDialog('ไม่พบข้อมูล Cerificates').then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(`/classmanage/classsection/${classroomId}`);
            }
          });
        }

    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/classmanage/classsection/${classroomId}`);
        }
      });
    } finally {
      setLoading(false)
    }
  };

  const createClassSectionData = async () => {
    if (!isFormValid()) {
      return;
    }

    try {

      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      const response = await create(formData);
      if (response) {
        showSuccessDialog('เพิ่มข้อมูลสำเร็จ')
        navigate(`/classmanage/classsection/${classroomId}`);
      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate(`/classmanage/classsection/${classroomId}`);
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/classmanage/classsection/${classroomId}`);
        }
      });
    } finally {
      setLoading(false)
    }
  }

  const updateClassSectionData = async (id) => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true)
      const response = await edit(id, formData);
        if (response) {
          showSuccessDialog('แก้ไขข้อมูลสำเร็จ')
          navigate(`/classmanage/classsection/${classroomId}`);
        }else{
          showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
            if (result.isConfirmed || result.isDismissed) {
              navigate(`/classmanage/classsection/${classroomId}`);
            }
          });
        }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate(`/classmanage/classsection/${classroomId}`);
        }
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate(`/classmanage/classsection/${classroomId}`);
    }

    fetchData();

    if (method === "view") {
      setIsView(true);
    }

  }, []);

  return (
      <div className="container-fluid">
        <div className="box-white">
          <div className="mt-3">
            <h4 className="px-3 mb-2">จัดการบทเรียน</h4>
              <h5 className="px-3 mb-2 text-header">จัดการบทเรียน {classroom ? `| ห้องสอน ${classroom.cla_name}`: ''}</h5>

            <Row className="mt-3 px-5">
              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                  ลำดับ 
                </span>
              </Col>
              <Col xs={12} md={4}>
                <input
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.no}
                  id="no"
                  name="no"
                  onChange={handleChange}
                  onKeyDown={(event) => {
                    const key = event.key;
                    if (!/^\d$/.test(key) && key !== 'Backspace' && key !== 'Delete') {
                      event.preventDefault();
                    }
                  }}
                />
              </Col>

              <Col xs={'auto'} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  ชื่อบทเรียน 
                </label>
              </Col>
              <Col xs={12} md={4}>
                <input
                  type="text"
                  className="inp-login fontLight font-size03"
                  value={formData.name}
                  name="name"
                  id="name"
                  onChange={handleChange}

                />
              </Col>
            </Row>

            <Row className="mt-3 px-5 align-self-center">
              <Col xs={'auto'} md={1} className="align-self-center">
                <label className="fontLight font-size03 colorGray01 wh-sp">
                  สถานะ {" "}
                </label>
              </Col>

              <Col sm={12} md={4} className="align-self-center px-5">
                <p className="wh-sp">
                  <Form.Check
                    className="px-5"
                    inline
                    type="radio"
                    label="ใช้งาน"
                    name="isActiveGroup"
                    id="Active"
                    value="true"
                    checked={formData.is_active === true}
                    onChange={handleIsActiveChange}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    label="ไม่ใช้งาน"
                    name="isActiveGroup"
                    id="Inactive"
                    value="false"
                    checked={formData.is_active === false}
                    onChange={handleIsActiveChange}
                  />
                </p>
              </Col>


              <Col xs={'auto'} md={1} className="align-self-center">
                <span className="fontLight font-size03 colorGray01 wh-sp">
                รูปแบบใบรับรอง 
                </span>
              </Col>
              <Col xs={12} md={4}>
                <Select
                  name="theme"
                  value={certificateOptions.find(
                    (option) => option.value === formData.theme
                  )}
                  onChange={handleSelectChange}
                  options={certificateOptions}
                  placeholder="Please select"
                />
                {/* <button className="btn-save m-1">View</button> */}
              </Col>
            </Row>
              

            <Row className="mt-3 px-5">
              <Col xs={6} className="d-flex justify-content-start">
                <button
                  className="btn-cancel m-1 mt-5 flex-1"
                  onClick={() => {
                    navigate(`/classmanage/classsection/${classroomId}`);
                  }}
                >
                  ยกเลิก
                </button>{" "}
                {!isView ? (
                  <button
                    className="btn-save m-1 mt-5"
                    onClick={(e) => {
                      method === "create"
                        ? createClassSectionData()
                        : updateClassSectionData(id);
                    }}
                    // disabled={!isFormValid()}
                  >
                    บันทึก
                  </button>
                ) : (
                  <></>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </div>
  );
};

export default TeacherClassSectionAction;
