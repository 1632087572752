import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import headerDefinitions from "../tableheaders/learnerclassreward.tableheader.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import helpers from "../helpers/function.helper";
import LoadingComponent from "../components/Loading";
import { getUsersList } from "../api/users.api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import RewardIcon from "../assets/icons/swalReward.svg";
import viewIcon from "../assets/icons/view.png";
import certIcon from "../assets/icons/cert.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import TableHeaderButton from "../components/TableHeaderButton";
import "./LearnerClassSection.css";
import SidePanelContent from "../components/SidePanelContent";
import { showRedeemDialog, showSuccessDialog } from "../components/Dialog";

const LearnerClassReward = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [selectedSection, setSelectedSection] = useState(null);
  const [showSidePanel, setShowSidePanel] = useState(false);

  const handleRedeem = () => {
    showRedeemDialog.then((result) => {
      if (result.isConfirmed) {
        showSuccessDialog('แลกของรางวัล สำเร็จ')
      }
    });
  };

  const handleViewClick = async (section) => {
    setSelectedSection(section);
    setShowSidePanel(true);
  };

  const handleClose = () => {
    setShowSidePanel(false);
    setSelectedSection(null);
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) handleClose();
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [handleClose]);

  const handleClickOutside = useCallback(
    (event) => {
      if (!event.target.closest(".side-panel")) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // -------------------- Data Table ----------------------------------------
  const headersData = headerDefinitions.columns;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataTable, setDatatable] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const tableData = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => headersData, [headersData]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useSortBy,
      usePagination
    );

  const toggleRowSelection = (Id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(Id)) {
        // If already selected, remove it
        return prev.filter((id) => id !== Id);
      } else {
        // Otherwise, add it
        return [...prev, Id];
      }
    });
  };

  const handleSort = async (columnId) => {
    // Will Sort By *accessor* in HeaderJSON File
    setSortConfig((prevSortConfig) => {
      let direction = "ASC";
      if (
        prevSortConfig.key === columnId &&
        prevSortConfig.direction === "ASC"
      ) {
        direction = "DESC";
      }
      return { key: columnId, direction };
    });
  };

  const handleNextPage = () => {
    setPageIndex((current) => {
      const nextPageIndex = current + 1;
      setCanNextPage(nextPageIndex < pageCount - 1);
      setCanPreviousPage(true);
      return nextPageIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((current) => {
      const previousPageIndex = Math.max(0, current - 1);
      setCanPreviousPage(previousPageIndex > 0);
      setCanNextPage(true);
      return previousPageIndex;
    });
  };

  // -------------------- Data Table ----------------------------------------

  const clearSearch = () => {
    setKeyword("");
    setSortConfig({ key: "", direction: "" });
    setRowsPerPage(10);
    setPageIndex(0);
  };

  const fetchData = async () => {
    console.log("Fetching data...");
    try {
      const { data, totalPages } = await getUsersList({
        page_number: pageIndex,
        data_per_page: rowsPerPage,
        keyword: keyword,
        sort_key: sortConfig.key,
        sort_order: sortConfig.direction,
      });
      if (data) {
        console.log("Data received:", data);
        setDatatable(data);
        setPageCount(totalPages);
        setCanNextPage(pageIndex < Math.ceil(totalPages / rowsPerPage) - 1);
        setCanPreviousPage(pageIndex > 0);
      }
    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // fetchData();
  }, []);

  return (
    <div className="content pb-5">
      <div className="container-fluid">
        <div className="box-white">
          {/* <h4>ประวัติแลกของรางวัล</h4> */}

          <div className="d-flex justify-content-between align-items-center">
            <div className="stat-box lessons class-info-section d-flex flex-column p-3">
              <h5>รหัสห้องเรียน C001</h5>
              <h4>
                ชื่อห้องเรียน{" "}
                <span className="text-primary">รถไฟฟ้า EV101</span>
              </h4>
              <div className="d-flex align-items-center mt-2">
                <span className="mx-2">
                  สถานะ: <span className="text-success">กำลังเรียน</span>
                </span>
                |{/* <div className="vertical-divider mx-2"></div> */}
                <span className="mx-2">
                  ระยะเวลา 1 ส.ค. 2567 ถึง 31 ส.ค. 2567
                </span>
                |{/* <div className="vertical-divider mx-2"></div> */}
                <span className="mx-2">ผู้สอน TEA-001 สิริชัย อัครวัฒน์</span>
              </div>
            </div>
            <div className="class-stats d-flex">
              <div className="stat-box lessons bg-purple text-center p-3 mx-2">
                <p>จำนวนบทเรียน</p>
                <h3 className="text-primary">10</h3>
              </div>
              <div className="stat-box score bg-green text-center p-3 mx-2">
                <p>คะแนนเก็บ</p>
                <h3 className="text-success">50</h3>
              </div>
            </div>
          </div>

          <h4 className="my-2">
            แลกของรางวัล I คะแนนคงเหลือ{" "}
            <span className="text-header">150 คะแนน</span>
          </h4>
          <div className="d-flex justify-content-between align-items-center mx-2 py-3">
            <div className='d-none d-md-block'>
              <span className="pr-2 align-self-center text-sm fw-bold">
                Show :{" "}
              </span>
              <label>
                <select
                  style={{ border: "none", cursor: "pointer" }}
                  className="select-page mr-2"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                </select>
              </label>
              <label>Entries</label>
            </div>
            <div className="d-flex search-container">
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="ค้นหา"
                className="search-input"
              />
              <button className="search-button" onClick={fetchData}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
          <div style={{ overflow: "auto" }}>
            <Table
              striped
              bordered
              {...getTableProps()}
              className="table text-center"
              style={{ borderCollapse: "collapse" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={"head"} {...headerGroup.getHeaderGroupProps()}>
                    {/* <th>ลำดับ</th> */}
                    {headerGroup.headers.map((column) => {
                      const { key, ...rest } = column.getHeaderProps(); // Separate key from other props
                      return (
                        <th key={key} {...rest}>
                          {column.render("Header")}
                          {/* Add a clickable sort icon */}
                          <TableHeaderButton
                            column={column}
                            sortConfig={sortConfig}
                            handleSort={handleSort}
                          />
                        </th>
                      );
                    })}
                    <th>เครื่องมือ</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                <tr className="space-table"></tr>
                {/* MOCK */}
                <tr>
                  <td>Apple Pencil</td>
                  <td>กิฟวอเชอร์</td>
                  <td>0/2</td>
                  <td>100</td>
                  <td>1 ส.ค. 2567 ถึง 31 ส.ค. 2567</td>
                  <td>
                    <button
                      className="btn-chp mx-1 flex-1"
                      onClick={() => handleRedeem()}
                    >
                      แลกของรางวัล
                    </button>
                  </td>
                </tr>
                {/* MOCK */}

                {rows.length > 0 ? (
                  rows.map((row, rowIndex) => {
                    prepareRow(row);
                    const actualIndex = pageIndex * rowsPerPage + rowIndex + 1;
                    const isSelected = selectedRowIds.includes(row.original.id);

                    // Extract key from row.getRowProps()
                    const { key, ...rest } = row.getRowProps();

                    return (
                      <tr
                        key={key} // Pass key directly here
                        {...rest}
                        onClick={() => toggleRowSelection(row.original.id)}
                        className={isSelected ? "selected-row" : ""}
                        style={isSelected ? { backgroundColor: "#f0f0f0" } : {}}
                      >
                        <td>{actualIndex}</td>
                        <td>{row.original?.ins_code ?? ""}</td>
                        <td>{row.original?.ins_name ?? ""}</td>
                        <td>
                          <span
                            style={{
                              color: row.original.is_active
                                ? "#87BD66"
                                : "#D95350",
                            }}
                          >
                            {helpers.getStatus(row.original.is_active)}
                          </span>
                        </td>
                        <td>
                          <button
                            className="mx-1 icon-btn"
                            style={{ background: "transparent" }}
                          >
                            <img
                              src={certIcon}
                              alt="Edit"
                              className="ic-actions"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan={100} style={{ textAlignLast: "center" }}>
                      ไม่พบข้อมูล
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <div
            className="py-3 mx-2"
            style={{ display: "flex", justifyContent: "right" }}
          >
            {/* Pagination */}
            <div className="mt-2">
              <span>
                {`${pageIndex * rowsPerPage + 1} - ${
                  (pageIndex + 1) * rowsPerPage
                } จาก ${pageCount} `}
              </span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handlePreviousPage}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <span> {pageIndex + 1}</span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handleNextPage}
                disabled={!canNextPage}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
              <span>{Math.ceil(pageCount / rowsPerPage)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LearnerClassReward;
