import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const resetPasswordWithToken = async (data) => {
  try {
    const toReset = {
        token: data?.token,
        newPassword: data?.newPassword,
    }
    const response = await instance.put(`/users/reset-password`, toReset);
    if(response.status === 200) {
        return response.data
    }else{
        return null
    }
  } catch (error) {
    return null
  }
}

export const changePassword = async (data) => {
  try {
      const toUpdate = {
          user_id: data?.id,
          new_password: data?.new_password,
      }
      const response = await instance.post(`/users/changepassword`,toUpdate);
      if(response.status === 200) {
          return response.data
      }else{
          return null
      }
    } catch (error) {
      return null
    }
}

export const getUsersList = async (query)=> {
    try {
        const response = await instance.get(`${API_BASE_URL}/users`,{ params: query });
        const data = response.data.data || [];
        const totalPages = response.data.total;
        return { data, totalPages };
      } catch (error) {

      }
};

export const getUsersID = async (id) => {
    try {
      const response = await instance.get(`${API_BASE_URL}/users/${id}`);
        if(response.status === 200) {
          return response.data.data
      }else{
          return null
      }
    } catch (error) {
      return null
    }
};

export const edit = async (id,data) => {
  try {
      const toUpdate = {
          user_id: id,
          fullname: data?.fullname,
          email: data?.email,
          phone: data?.phone,
      }
      const response = await instance.put(`${API_BASE_URL}/users/${id}`,toUpdate);
      if(response.status === 200) {
          return response.data
      }else{
          return null
      }
    } catch (error) {
      return null
    }
}


export const exportDataDownload = (param, namePage) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await instance.get(param, {
        headers: {
          Authorization: `Bearer ` + localStorage.getItem("token"),
        },
        responseType: "arraybuffer", // Set the response type to arraybuffer for binary data
      });

      const blob = new Blob([response.data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);

      // Create an anchor element and simulate a click to download the file
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", namePage + ".xlsx"); // Set the download attribute with the filename
      document.body.appendChild(link);
      link.click();

      // Clean up

      document.body.removeChild(link);
      URL.revokeObjectURL(url);

      resolve(); // Resolve the promise when the operation is complete
    } catch (error) {
      console.error("Error fetching file:", error);
      reject(error); // Reject the promise in case of an error
    }
  });
};
