import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

const instance = axios.create({
  baseURL: API_BASE_URL,
});

instance.interceptors.response.use(
  (response) => response,
  (error) => {

    return Promise.reject(error);
  }
);

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  try {
    const response = await instance.post(`/auth/login`, {
      username,
      password,
    });
    return response.data;
  } catch (error) {

  }
};

export const checkToken = async (token)=> {
  try {
    const response = await instance.post(`/auth/check-token`, {token}, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    return {
      status: 'error',
      message: 'failed',
      isValid: false
    }
  }
};

export const refreshAccessToken = async () => {
  try {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) throw new Error('No refresh token available');

    const response = await instance.post(`/auth/refresh-token`, { token: refreshToken });
    const newToken = response.data.data.newToken;
    localStorage.setItem('token', newToken);
    return newToken;
  } catch (error) {
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await instance.post(`/auth/forgot-password`, { email });
    return response.data;
  } catch (error) {
    
  }
};