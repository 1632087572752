import React from 'react';
import { useLocation , BrowserRouter as Router, Route, Routes, Navigate,  } from 'react-router-dom';
import Layout from './components/Layout';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import { UserProvider, useUserContext } from './contexts/UserContext';
import EnumRoles from './constants/EnumRoles';
import './App.css';
import InstitutionManage from './pages/InstitutionManage';
import License from './pages/License';
import TeacherManage from './pages/TeacherManage';
import LearnerManage from './pages/LearnerManage';
import ModelVRManage from './pages/ModelVRManage';
import CertificateManage from './pages/CertificateManage';
import LearnerClass from './pages/LearnerClass';
import RewardHistory from './pages/RewardHistory';
import TeacherClassManage from './pages/TeacherClassManage';
import TeacherClassManageAction from './pages/TeacherClassManageAction';
import TeacherImport from './pages/TeacherImport';
import TeacherRewardManage from './pages/TeacherRewardManage';
import AdminDashboard from './pages/AdminDashboard';
import StudentDashboard from './pages/StudentDashboard';
import RewardCategory from './pages/RewardCategory';
import TestManage from './pages/TestManage';
import CertificateManageAction from './pages/CertificateManageAction';
import TeacherClassSection from './pages/TeacherClassSection';
import TeacherClassSectionAction from './pages/TeacherClassSectionAction';
import InstitutionAction from './pages/InstitutionAction';
import LicenseAction from './pages/LicenseAction';
import LearnerImport from './pages/LearnerImport';
import LearnerLayout from './components/LearnerLayout';
import LearnerRewardHistory from './pages/LearnerRewardHistory';
import LearnerClassSection from './pages/LearnerClassSection';
import LearnerClassReward from './pages/LearnerClassReward';
import ModelVRManageAction from './pages/ModelVRManageAction';
import LearnerInvite from './pages/LearnerInvite';
import TeacherClassChapter from './pages/TeacherClassChapter';
import TeacherClassChapterAction from './pages/TeacherClassChapterAction';
import ClassLearner from './pages/ClassLearner';
import TeacherClassLesson from './pages/TeacherClassLesson';
import TeacherClassLessonAction from './pages/TeacherClassLessonAction';
import LearnerProgress from './pages/LearnerProgress';
import UserChangePassword from './pages/๊UserChangePassword';
import UserAction from './pages/UserAction';
import ResetPassword from './pages/ResetPassword';
import TestManageActoion from './pages/TestManageAction';
import RewardCategoryAction from './pages/RewardCategoryAction';
import TeacherRewardManageAction from './pages/TeacherRewardManageAction';
import TestImport from './pages/TestImport';

const ProtectedRoute = ({ children, roles }) => {
  const { user } = useUserContext();
  
  if (!user) {
    return <Navigate to="/login" />;
  }

  if (!roles.includes(user.role?.name)) {
    return <Navigate to="/login" />;
  }
  return children;
};

const App = () => {
  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/login" element={<Login />} />

          <Route element={<LearnerLayout />}>
            <Route 
                path="/myclass"
                element={
                  <ProtectedRoute roles={[EnumRoles.LEARNER]}>
                    <LearnerClass />
                  </ProtectedRoute>
                }
            />
            <Route 
                path="/myclasssection"
                element={
                  <ProtectedRoute roles={[EnumRoles.LEARNER]}>
                    <LearnerClassSection />
                  </ProtectedRoute>
                }
            />
            <Route 
                path="/redeem-reward"
                element={
                  <ProtectedRoute roles={[EnumRoles.LEARNER]}>
                    <LearnerClassReward />
                  </ProtectedRoute>
                }
            />
            <Route 
                path="/reward-history"
                element={
                  <ProtectedRoute roles={[EnumRoles.LEARNER]}>
                    <LearnerRewardHistory />
                  </ProtectedRoute>
                }
            />
          </Route>


          <Route element={<Layout />}>
            <Route
              path="/"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.TEACHER, EnumRoles.STAFF]}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/changepassword"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF]}>
                  <UserChangePassword />
                </ProtectedRoute>
              }
            />

            <Route
              path="/update-user"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF]}>
                  <UserAction />
                </ProtectedRoute>
              }
            />

            <Route
              path="/admin-dashboard"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF]}>
                  <AdminDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/student-dashboard"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <StudentDashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/institutions"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF]}>
                  <InstitutionManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/institutions/action"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <InstitutionAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/institutions/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <InstitutionAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/licenses"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF]}>
                  <License />
                </ProtectedRoute>
              }
            />
            <Route
              path="/licenses/action"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <LicenseAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/teacher-user"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <TeacherManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="teacher-user/import"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF]}>
                  <TeacherImport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/learner-user"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <LearnerManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/learner-user/import"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <LearnerImport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/modelvr"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <ModelVRManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/modelvr/action"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <ModelVRManageAction/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/modelvr/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <ModelVRManageAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/certificate"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <CertificateManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/certificate/action"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <CertificateManageAction/>
                </ProtectedRoute>
              }
            />
            <Route
              path="/certificate/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.SUPERADMIN, EnumRoles.ADMIN, EnumRoles.STAFF, EnumRoles.TEACHER]}>
                  <CertificateManageAction/>
                </ProtectedRoute>
              }
            />
            {/* <Route
              path="/myclass"
              element={
                <ProtectedRoute roles={[EnumRoles.LEARNER]}>
                  <LearnerClass />
                </ProtectedRoute>
              }
            /> */}
            <Route
              path="/reward-redeem"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER, EnumRoles.LEARNER]}>
                  <RewardHistory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classmanage"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classlearner/:id" // classroom_id
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <ClassLearner />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classlearner-progress" // learner_id
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <LearnerProgress />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classmanage/action"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassManageAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classmanage/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassManageAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classmanage/classsection/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassSection />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classmanage/classroom/invite/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <LearnerInvite />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classmanage/classsection/action"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassSectionAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classmanage/classsection/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassSectionAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classsection/chapter"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassChapter />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classsection/chapter/action"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassChapterAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/classsection/chapter/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassChapterAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chapter/lesson/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassLesson />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chapter/lesson/action"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassLessonAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chapter/lesson/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherClassLessonAction />
                </ProtectedRoute>
              }
            />

            <Route
              path="/rewardmanage"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherRewardManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rewardmanage/action"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherRewardManageAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/rewardmanage/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TeacherRewardManageAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reward-category"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <RewardCategory />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reward-category/action"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <RewardCategoryAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/reward-category/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <RewardCategoryAction />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test-manage"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TestManage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test-manage/import"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TestImport />
                </ProtectedRoute>
              }
            />
            <Route
              path="/test-manage/action"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TestManageActoion />
                </ProtectedRoute>
              }
            />
             <Route
              path="/test-manage/action/:id"
              element={
                <ProtectedRoute roles={[EnumRoles.TEACHER]}>
                  <TestManageActoion />
                </ProtectedRoute>
              }
            />
            {/* Add more routes as needed */}
          </Route>
        </Routes>
      </UserProvider>
    </Router>
  );
};

export default App;
