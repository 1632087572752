import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { useUserContext } from '../contexts/UserContext';
import './LearnerHeader.css';
import helpers from '../helpers/function.helper';
import sidebarLogo from '../assets/Header_logo.png';

const LearnerHeader = () => {
  const { user } = useUserContext();
  const initial = user?.name ? user.name.charAt(0).toUpperCase() : '';

  return (
    <nav className={`learner-main-header navbar navbar-expand navbar-white navbar-light`}>
      <a href="#" className="brand-link">
        <img src={sidebarLogo} alt="SkillsUp" className="brand-image" />
      </a>
      <ul className="navbar-nav ml-auto user-info-container">
        <li className="nav-item d-none d-sm-inline-block">
          <div className="user-info text-right">
            <span className="nav-link">{user?.name}</span>
            <span className="nav-link">{helpers.getRoleName(user?.role?.name)}</span>
          </div>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
          <div className="user-avatar">{initial}</div>
        </li>
      </ul>
    </nav>
  );
};

export default LearnerHeader;
