import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Swal from "sweetalert2";
import { getByID, create, edit } from "../api/modelvr.api";
import withLoading from "../hoc/withLoading";
import ImageUpload from "../components/ImageUpload";
import Select from "react-select";
import { showCreateDialog, showEditDialog, showErrorDialog, showSuccessDialog } from "../components/Dialog";

const ModelVRManageAction = () => {
  const [isLoading, setLoading] = useState(false);
  const [isView, setIsView] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();
  const [method, setMethod] = useState("");
  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [removedImages, setRemovedImages] = useState([]);
  const [newImages, setNewImages] = useState([]);

  const handleImageUpload = (uploadedFiles) => {
    setNewImages([...newImages, ...uploadedFiles]);
  };
  const [selectedVROption, setSelectedVROption] = useState(null);
  const [vrOptions, setVROptions] = useState([
    {
      label: "VR Information",
      value: "vr-info",
    },
    {
      label: "VR Interaction",
      value: "vr-interaction",
    },
  ]);

  const [formData, setFormData] = useState({
    mvr_code: "",
    mvr_name: "",
    mvr_detail: "",
    mvr_score: "",
    mvr_type: "vr-interaction",
    is_active: true,
  });

  const isFormValid = () => {
    const { mvr_code, mvr_name, mvr_detail, mvr_score, mvr_type } = formData;
  
    // Ensure institution, count, and dates are filled in
    if (!mvr_code || !mvr_name || !mvr_detail || !mvr_score || !mvr_type) {
      showErrorDialog('กรุณากรอกข้อมูลให้ครบถ้วน');
      return false;
    }
  
    // All validations passed
    return true;
  };

  const handleIsActiveChange = (e) => {
    setFormData({ ...formData, is_active: e.target.value === "true" });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  }

  const fetchData = async (id) => {
    try {
      setLoading(true);
      const response = await getByID(id);
      if (response) {
        setFormData({
          id: response.mvr_id,
          mvr_code: response.mvr_code,
          mvr_name: response.mvr_name,
          mvr_detail: response.mvr_detail,
          mvr_score: response.mvr_score,
          mvr_type: response.mvr_type,
          is_active: response.is_active,
        });

        // Find the option that matches the mvr_type
        const matchedOption = vrOptions.find(
          (option) => option.value === response.mvr_type
        );

        // Set the selected option if a match is found
        setSelectedVROption(matchedOption || null);

        const mvr_images = response.modelvr_images;

        if (mvr_images && mvr_images.length > 0) {
          setImagePreviews(
            mvr_images.map((item, index) => ({
              src: item.mvri_image,
              name: `image_${index}`,
              id: index,
            }))
          );
        }
      } else {
        showErrorDialog('ไม่พบข้อมูลแบบจำลองเสมือนจริง').then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/modelvr");
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then(async (result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/modelvr");
        }
      });
    } finally {
      setLoading(false)
    }
  };

  const createMVRData = async () => {
    if (!isFormValid()) {
      return;
    }
    try {
      const result = await showCreateDialog();
      if (!result.isConfirmed) {
        return; 
      }
      setLoading(true);
      const response = await create(formData, newImages, removedImages);
      if (response) {
        await showCreateDialog('เพิ่มข้อมูลสำเร็จ');
        navigate("/modelvr");
      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/modelvr");
          }
        });
      }
    } catch (error) {
      showErrorDialog('เกิดข้อผิดพลาด').then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/modelvr");
        }
      });
    }
  };

  const updateMVRData = async (id) => {
      if (!isFormValid()) {
        return;
      }
    try {
      const result = await showEditDialog();
      if (!result.isConfirmed) {
        return; 
      }

      setLoading(true);
      const response = await edit(id, formData, newImages, removedImages);
      if (response) {
        await showSuccessDialog('แก้ไขข้อมูล');
        navigate("/modelvr");
      } else {
        showErrorDialog('เกิดข้อผิดพลาด').then((result) => {
          if (result.isConfirmed || result.isDismissed) {
            navigate("/modelvr");
          }
        });
      }
    } catch (error) {
      console.log(error)

      showErrorDialog('เกิดข้อผิดพลาด').then((result) => {
        if (result.isConfirmed || result.isDismissed) {
          navigate("/modelvr");
        }
      });
    } finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const method = urlParams.get("method");

    if (method !== "view" && method !== "create" && method !== "edit") {
      navigate("/modelvr");
    }

    if (method === "view" || method === "edit") {
      fetchData(id);
    }

    if (method === "view") {
      setIsView(true);
    }

    setMethod(method);
  }, []);

  return (
    <div className="container-fluid">
      <div className="box-white">
        <div className="mt-3">
          <h4 className="px-3 mb-2">แบบจำลองเสมือนจริง</h4>
          <h5 className="px-3 mb-2 text-header">
            จัดการข้อมูลแบบจำลองเสมือนจริง
          </h5>

          <Row className="mt-3 px-5">
            <Col xs={12} md={4}>
              <span className="fw-bold font-size03 colorGray01 wh-sp">
                รหัสแบบจำลองเสมือนจริง
              </span>
              <input
                disabled={isView}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.mvr_code}
                name="mvr_code"
                id="mvr_code"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={12} md={4}>
              <span className="fw-bold font-size03 colorGray01 wh-sp">
                ชื่อแบบจำลองเสมือนจริง
              </span>
              <input
                disabled={isView}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.mvr_name}
                name="mvr_name"
                id="mvr_name"
                onChange={handleChange}
              />
            </Col>
            <Col xs={12} md={4}>
              <span className="fw-bold font-size03 colorGray01 wh-sp">
                รายละเอียดแบบจำลองเสมือนจริง
              </span>
              <input
                disabled={isView}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.mvr_detail}
                name="mvr_detail"
                id="mvr_detail"
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            {/* <Col xs={12} md={4} className="align-self-center">
              <span className="fw-bold font-size03 colorGray01 wh-sp">
                ประเภท
              </span>
              <Select
                isDisabled={isView}
                name="mvr_type"
                value={vrOptions.find(
                  (option) => option.value === formData.mvr_type
                )}
                options={vrOptions}
                onChange={handleSelectChange}
                placeholder="Please select"
              />
            </Col> */}

            <Col xs={12} md={4}>
              <span className="fw-bold font-size03 colorGray01 wh-sp">
                คะแนน
              </span>
              <input
                disabled={isView}
                type="text"
                className="inp-login fontLight font-size03"
                value={formData.mvr_score}
                name="mvr_score"
                id="mvr_score"
                onChange={handleChange}
                onKeyDown={(event) => {
                  const key = event.key;
                  if (
                    !/^\d$/.test(key) &&
                    key !== "Backspace" &&
                    key !== "Delete"
                  ) {
                    event.preventDefault();
                  }
                }}
              />
            </Col>
          </Row>

          {/* 2 */}
          <Row className="mt-3 px-5">
            <Col xs={12} md={4}>
              <span className="fontLight font-size03 colorGray01 wh-sp">
                รูปแบบตัวอย่าง
              </span>
              {isView ? (
                <div className="image-preview-container">
                  {imagePreviews.map((preview, index) => (
                    <div key={index + 1} className="image-preview">
                      <img
                        src={preview.src}
                        alt={preview.name}
                        width={'150px'}
                        height={'auto'}
                        className="image-preview-thumb"
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <ImageUpload
                  onUpload={handleImageUpload}
                  datDetailImage={() => {}}
                  dtStatusClear={false}
                  initialPreviews={imagePreviews}
                  maxImages={5}
                />
              )}
            </Col>
          </Row>

          <Row className="mt-3 px-5 align-self-center">
            <Col xs={4} md={"auto"} className="align-self-center">
              <label className="fontLight font-size03 colorGray01 wh-sp">
                สถานะ{" "}
              </label>
            </Col>

            <Col sm={12} md={4} className="align-self-center px-5">
              <p className="wh-sp">
                <Form.Check
                 disabled={isView}
                  className="px-5"
                  inline
                  type="radio"
                  label="ใช้งาน"
                  name="isActiveGroup"
                  id="Active"
                  value="true"
                  checked={formData.is_active === true}
                  onChange={handleIsActiveChange}
                />
                <Form.Check
                 disabled={isView}
                  inline
                  type="radio"
                  label="ไม่ใช้งาน"
                  name="isActiveGroup"
                  id="Inactive"
                  value="false"
                  checked={formData.is_active === false}
                  onChange={handleIsActiveChange}
                />
              </p>
            </Col>
          </Row>

          <Row className="mt-3 px-5">
            <Col xs={6} className="d-flex justify-content-start">
              <button
                className="btn-cancel m-1 mt-5 flex-1"
                onClick={() => {
                  navigate("/modelvr");
                }}
              >
                ยกเลิก
              </button>{" "}
              {!isView ? (
                <button
                  className="btn-save m-1 mt-5"
                  onClick={(e) => {
                    method === "create" ? createMVRData() : updateMVRData(id);
                  }}
                  // disabled={!isFormValid()}
                >
                  บันทึก
                </button>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default withLoading(ModelVRManageAction);
