import React from 'react';
import './ScoreCard.css'

const ScorerCard = ({ rank, percentage, name, id, points }) => {
  const initials = name.split(' ').map(word => word[0]).join('');

  return (
    <div className="scorer-card">
      <div className="scorer-header">
        <h3>{percentage}%</h3>
        <p className="rank">สูงสุดอันดับ {rank}</p>
      </div>
      <div className="scorer-body">
        <div className="scorer-avatar">{initials}</div>
        <div className="scorer-details">
          <p style={{flex:2}}>{name}</p>
          <p style={{flex:1}}>ID : {id}</p>
          <p style={{flex:1}}>Point : {points}</p>
        </div>
      </div>
    </div>
  );
};

export default ScorerCard;
