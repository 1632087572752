import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTable, usePagination, useSortBy } from "react-table";
import withLoading from "../hoc/withLoading";
import headerDefinitions from "../tableheaders/instutition.tableheader.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import helpers from "../helpers/function.helper";
import { getList, create, edit } from "../api/instutition.api";
import Swal from "sweetalert2";
import viewIcon from "../assets/icons/view.png";
import editIcon from "../assets/icons/edit.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
  faPlus,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import TableHeaderButton from "../components/TableHeaderButton";
import "./ViewSide.css";

const baseURL = process.env.REACT_APP_API_ENDPOINT;
const InstitutionManage = () => {
  const navigate = useNavigate();
  const [keyword, setKeyword] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [selectedData, setSelectedData] = useState(null);

  const handleViewClick = async (data) => {
    setSelectedData(data);
    setShowSidePanel(true);
  };

  const handleClose = useCallback(() => {
    setShowSidePanel(false);
    setSelectedData(null);
  }, []);

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.keyCode === 27) handleClose();
    };

    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [handleClose]);

  const handleClickOutside = useCallback(
    (event) => {
      if (!event.target.closest(".side-panel")) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  // -------------------- Data Table ----------------------------------------
  const headersData = headerDefinitions.columns;
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "" });
  const [pageCount, setPageCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [dataTable, setDatatable] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [canNextPage, setCanNextPage] = useState(true);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const tableData = useMemo(() => dataTable, [dataTable]);
  const columns = useMemo(() => headersData, [headersData]);
  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data: tableData,
      },
      useSortBy,
      usePagination
    );

  const toggleRowSelection = (Id) => {
    setSelectedRowIds((prev) => {
      if (prev.includes(Id)) {
        return prev.filter((id) => id !== Id);
      } else {
        return [...prev, Id];
      }
    });
  };

  const handleSort = async (columnId) => {
    setSortConfig((prevSortConfig) => {
      let direction = "ASC";
      if (
        prevSortConfig.key === columnId &&
        prevSortConfig.direction === "ASC"
      ) {
        direction = "DESC";
      }
      return { key: columnId, direction };
    });
  };

  const handleNextPage = () => {
    setPageIndex((current) => {
      const nextPageIndex = current + 1;
      setCanNextPage(nextPageIndex < pageCount - 1);
      setCanPreviousPage(true);
      return nextPageIndex;
    });
  };

  const handlePreviousPage = () => {
    setPageIndex((current) => {
      const previousPageIndex = Math.max(0, current - 1);
      setCanPreviousPage(previousPageIndex > 0);
      setCanNextPage(true);
      return previousPageIndex;
    });
  };

  // -------------------- Data Table ----------------------------------------

  const clearSearch = () => {
    setKeyword("");
    setSortConfig({ key: "", direction: "" });
    setRowsPerPage(10);
    setPageIndex(0);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const { data, totalPages } = await getList({
        page_number: pageIndex,
        data_per_page: rowsPerPage,
        keyword: keyword,
        sort_key: sortConfig.key,
        sort_order: sortConfig.direction,
      });
      if (data) {
        setDatatable(data);
        setPageCount(totalPages);
        setCanNextPage(pageIndex < Math.ceil(totalPages / rowsPerPage) - 1);
        setCanPreviousPage(pageIndex > 0);
      }
    } catch (error) {
      console.error("Fetch Data Error:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageIndex, rowsPerPage, sortConfig]);

  return (
      <div className="container-fluid">
        <div className="box-white">
          <h2>สถาบัน</h2>

          <Row className="mb-3">
            <Col md={"auto"}>
              <button
                className="custom-btn btn-create m-1"
                onClick={(e) => {
                  navigate(`/institutions/action?method=create`);
                }}
              >
                <FontAwesomeIcon icon={faPlus} />{" "}
                <span className="mx-1">สร้างสถาบันใหม่</span>
              </button>
            </Col>
          </Row>
          <div className="d-flex justify-content-between align-items-center mx-2 py-3">
            <div className='d-none d-md-block'>
              <span className="pr-2 align-self-center text-sm fw-bold">
                Show :{" "}
              </span>
              <label>
                <select
                  style={{ border: "none", cursor: "pointer" }}
                  className="select-page mr-2"
                  value={rowsPerPage}
                  onChange={(e) => setRowsPerPage(Number(e.target.value))}
                >
                  <option value={10}>10</option>
                  <option value={25}>25</option>
                  <option value={50}>50</option>
                  <option value={100}>100</option>
                  <option value={200}>200</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                </select>
              </label>
              <label>Entries</label>
            </div>
            <div className="d-flex search-container">
              <input
                type="text"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="ค้นหา"
                className="search-input"
              />
              <button className="search-button" onClick={fetchData}>
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </div>
          <div style={{ overflow: "auto" }}>
            <Table
              striped
              bordered
              {...getTableProps()}
              className="table text-center"
              style={{ borderCollapse: "collapse" }}
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr key={"head"} {...headerGroup.getHeaderGroupProps()}>
                    <th>ลำดับ</th>
                    {headerGroup.headers.map((column) => {
                      const { key, ...rest } = column.getHeaderProps(); // Separate key from other props
                      return (
                        <th key={key} {...rest}>
                          {column.render("Header")}
                          {/* Add a clickable sort icon */}
                          <TableHeaderButton
                            column={column}
                            sortConfig={sortConfig}
                            handleSort={handleSort}
                          />
                        </th>
                      );
                    })}
                    <th>เครื่องมือ</th>
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                <tr className="space-table"></tr>
                {rows.length > 0 ? (
                  rows.map((row, rowIndex) => {
                    prepareRow(row);
                    const actualIndex = pageIndex * rowsPerPage + rowIndex + 1;
                    const isSelected = selectedRowIds.includes(row.original.id);

                    const { key, ...rest } = row.getRowProps();

                    return (
                      <tr
                        key={key} // Pass key directly here
                        {...rest}
                        onClick={() => toggleRowSelection(row.original.id)}
                        className={isSelected ? "selected-row" : ""}
                        style={isSelected ? { backgroundColor: "#f0f0f0" } : {}}
                      >
                        <td>{actualIndex}</td>
                        <td>{row.original?.ins_code ?? ""}</td>
                        <td>{row.original?.ins_name ?? ""}</td>
                        <td>{row.original?.ins_contact ?? ""}</td>
                        <td>{row.original?.ins_tel ?? ""}</td>
                        <td>
                          <span
                            style={{color: helpers.getStatusColor(row.original.is_active)}}
                          >
                            {helpers.getStatus(row.original.is_active)}
                          </span>
                        </td>
                        <td>
                          <button
                            className="mx-1 icon-btn"
                            style={{ background: "transparent" }}
                            onClick={() => handleViewClick(row.original)}
                          >
                            <img
                              src={viewIcon}
                              alt="View"
                              className="ic-actions"
                            />
                          </button>

                          <button
                            className="mx-1 icon-btn"
                            style={{ background: "transparent" }}
                            onClick={(e) => {
                              navigate(
                                `/institutions/action/${row.original?.ins_id}?method=edit`
                              );
                            }}
                          >
                            <img
                              src={editIcon}
                              alt="Edit"
                              className="ic-actions"
                            />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan={100} style={{ textAlignLast: "center" }}>
                      ไม่พบข้อมูล
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>

          <div
            className="py-3 mx-2"
            style={{ display: "flex", justifyContent: "right" }}
          >
            {/* Pagination */}
            <div className="mt-2">
              <span>
                {`${pageIndex * rowsPerPage + 1} - ${
                  (pageIndex + 1) * rowsPerPage
                } จาก ${pageCount} `}
              </span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handlePreviousPage}
                disabled={!canPreviousPage}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <span> {pageIndex + 1}</span>
              <button
                className="mx-1 px-3 icon-btn"
                onClick={handleNextPage}
                disabled={!canNextPage}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
              <span>{Math.ceil(pageCount / rowsPerPage)}</span>
            </div>
          </div>

          {/* Side Panel */}
          {selectedData && (
            <div
              style={{ width: "50%" }}
              className={`side-panel ${showSidePanel ? "open" : ""}`}
            >
              <div className="side-panel-header">
                <h3>รายละเอียดสถาบัน</h3>
                <Button
                  variant="link"
                  onClick={handleClose}
                  className="close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </Button>
              </div>

              <div className="side-panel-title">
                <h5>สถาบัน: {selectedData.ins_name}</h5>
              </div>

              <div className="side-panel-content">
                <div className="info-section">
                  <span className="label">ผู้ดูแล :</span>
                  <p>{selectedData.ins_contact ?? "-"}</p>
                </div>
                <div className="info-section">
                  <span className="label">เบอร์ติดต่อ :</span>
                  <p>{selectedData.ins_tel ?? "-"}</p>
                </div>
                {/* <div className="info-section">
                  <span className="label">รหัสไปรษณีย์:</span>
                  <p>{selectedData.zip_code ?? '-'}</p>
                </div> */}
                <div className="info-section">
                  <span className="label">แผนที่ :</span>
                  <p>
                    <a
                      href={selectedData.ins_map ?? ""}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Maps
                    </a>
                  </p>
                </div>
                <div className="info-section">
                  <span className="label">สถานะ :</span>
                  <p
                    style={{
                      color: selectedData.is_active ? "#87BD66" : "#D95350",
                    }}
                  >
                    {helpers.getStatus(selectedData.is_active) ?? "-"}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
  );
};

export default withLoading(InstitutionManage);
