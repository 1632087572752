import { getInstance } from './Instance';

const instance = getInstance()
const API_BASE_URL = process.env.REACT_APP_API_ENDPOINT;

export const getList = async (query)=> {
    try {
        const response = await instance.get(`/teacher`,{ params: query });

        if(response.status === 200){
            const data = response.data.data || [];
            const totalPages = response.data.total;
            return { data, totalPages };
        }else{
            return null
        }

      } catch (error) {
        console.log(error)
        return null
      }
};

export const getByID = async (id) => {
    try {
        const response = await instance.get(`/teacher/${id}`);
        if(response.status === 200){
            return response.data.data;
        }else{
            return null
        }
      } catch (error) {
        return null
      }
};

export const create = async (data) => {
    try {
        const toCreate = {
            ins_id: data?.institution,
            lic_count: data?.count,
            lic_ready: data?.ready,
            lic_expired: data?.expired,
        }
        const response = await instance.post(`/teacher`,toCreate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const edit = async (data) => {
    try {
        const toUpdate = {
            lic_id: data?.id,
            ins_id: data?.institution,
            lic_count: data?.count,
            lic_ready: data?.ready,
            lic_expired: data?.expired,
        }
        const response = await instance.put(`/teacher/${data.id}`,toUpdate);
        if(response.status === 200) {
            return response.data
        }else{
            return null
        }
      } catch (error) {
        return null
      }
}

export const changePassword = async (data) => {
  try {
      const toUpdate = {
          user_id: data?.id,
          new_password: data?.newpassword,
      }
      const response = await instance.put(`/users/changepassword`,toUpdate);
      if(response.status === 200) {
          return response.data
      }else{
          return null
      }
    } catch (error) {
      return null
    }
}

export const verifyTeacherImport = async (body) => {
    try {
      const response = await instance.post("/users/verify", body);
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`error durring fetch api`);
      }
    } catch (error) {}
};
  
export const importTeacherData = async (body) => {
    try {
      const response = await instance.post("/users/import-users", body);
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`error durring fetch api`);
      }
    } catch (error) {}
};

export const importLearnerData = async (body) => {
  try {
    const response = await instance.post("/users/import-learner", body);
    if (response.status === 200) {
      return response;
    } else {
      throw new Error(`error durring fetch api`);
    }
  } catch (error) {}
};
  
