import React from 'react';
import './LearnerFooter.css';

const LearnerFooter = () => {
  return (
    <footer className="learner-main-footer">
      <div className="footer-content">
        <div>
          Copyright © 2024 <a href="https://www.example.com">LMS</a>. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

export default LearnerFooter;
