import React, { useState, useEffect } from "react";
import './ImageUpload.css';
import { showErrorDialog } from "./Dialog";

const ImageUpload = ({ onUpload, datDetailImage = () => {}, dtStatusClear, initialPreviews = [], maxImages = 5 }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [fileNames, setFileNames] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  useEffect(() => {
    if (initialPreviews.length > 0) {
      setImagePreviews(initialPreviews);
    }
  }, [initialPreviews]);

  const handleDragEnter = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setIsDragging(false);
    const files = Array.from(e.dataTransfer.files);
    handleFiles(files);
  };

  const handleFiles = (files) => {
    // Check if adding these files would exceed the maxImages limit
    if (imagePreviews.length + files.length > maxImages) {
      showErrorDialog(`ไม่สามารถ อัปโหลดรูปได้เกิน ${maxImages} รูป`)
      return;
    }

    const validFiles = [];
    const invalidFiles = [];

    files.forEach(file => {
      if (file.type.startsWith("image/")) {
        validFiles.push(file);
      } else {
        invalidFiles.push(file);
      }
    });

    if (invalidFiles.length > 0) {
      showErrorDialog(`กรุณาตรวจสอบข้อมูล ไฟล์ต้องเป็นรูปภาพเท่านั้น`)
      return;
    }

    if (validFiles.length > 0) {
      const newFileNames = validFiles.map(file => file.name);
      setFileNames([...fileNames, ...newFileNames]);

      const readerPromises = validFiles.map(file => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            resolve({ name: file.name, src: event.target.result, file }); // Include the File object
          };
          reader.readAsDataURL(file);
        });
      });

      Promise.all(readerPromises).then((fileData) => {
        setImagePreviews([...imagePreviews, ...fileData]);
        onUpload([...imagePreviews, ...fileData.map(f => f.file)]); // Pass File objects to parent
        datDetailImage([...fileNames, ...newFileNames]);
      });
    }
  };

  const handleClick = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.multiple = true; // Allow multiple file selection
    input.onchange = (e) => {
      const files = Array.from(e.target.files);
      handleFiles(files);
    };
    input.click();
  };

  const handleImageRemove = (index) => {
    const newFileNames = [...fileNames];
    const newImagePreviews = [...imagePreviews];
    newFileNames.splice(index, 1);
    newImagePreviews.splice(index, 1);
    setFileNames(newFileNames);
    setImagePreviews(newImagePreviews);
    onUpload(newImagePreviews.map(f => f.file)); // Update parent with remaining files
    datDetailImage(newFileNames);
  };

  return (
    <>
      <div
        className={`image-dropzone-container ${isDragging ? "dragging" : ""}`}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        onClick={handleClick}
      >
        <div className="image-dropzone-message">
          Upload File
        </div>
        <button className="select-file-button btn-upload">Choose files</button>
      </div>
      <div className="image-preview-container">
        {imagePreviews.map((image, index) => (
          <div key={index + 1} className="image-preview-item">
            <img src={image.src} alt={`Preview ${index}`} className="image-preview" />
            <button className="remove-image-button" onClick={() => handleImageRemove(index)}>
              &times;
            </button>
          </div>
        ))}
      </div>
      <div className="col-12 mb-2 mb-lg-2 wh-sp-text">
        <span className="fs-12 fw-bold text-warning mb-1">
          รองรับไฟล์รูปภาพเท่านั้น &nbsp;
        </span>
      </div>
    </>
  );
};

export default ImageUpload;
