const EnumRoles = {
    SUPERADMIN: 'superadmin',
    ADMIN: 'admin',
    TEACHER: 'teacher',
    ASSISTANT: 'assistant',
    LEARNER: 'learner',
    STAFF: 'staff'
  };
  
  export default EnumRoles;
  